import React, { FC, useContext, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar, GridValidRowModel, plPL } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import { Vehicle } from '../../../interfaces';
import { createRowData } from './utils';
import { deleteVehicle } from '../../../api/services/VehicleService';
import TableEmptyState from '../../../components/TableEmptyState';
import VehicleTypeTag from '../../../components/shared/Tags/VehicleTypeTag';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import { RoutesVars } from '../../../const/constRoutes';
import { toast } from 'react-hot-toast';
import AlertDialog from '../../VehicleNotificationPage/components/AlertDialog';
import { getSelectedRowsToExport } from '../../../utils/tables/utils';

type Props = {
  vehicles: Vehicle[];
  isLoading: boolean;
};

const AllVehiclesTable: FC<Props> = ({ vehicles, isLoading }) => {
  const { state: { user } } = useContext(UserContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState('');

  const deleteMutation = useMutation({
    mutationFn: (vehicleId: string) => deleteVehicle(vehicleId),
    onSuccess: () => {
      queryClient.invalidateQueries(['getVehicles', user?.id]);
      toast.success('Poprawnie usunięto pojazd.');
    },
    onError: () => { toast.error('Błąd podczas usuwania pojazdu.'); },
    meta: { hideDefaultToast: true }
  });

  const handleDeleteVehicle = (vehicleId: string) => {
    deleteMutation.mutate(vehicleId);
  };

  const handleCloseDialog = () => {
    handleDeleteVehicle(selectedVehicleId);
    setOpenDialog(false);
  };

  const handleClickOpenDialog = (vehicle: Vehicle & { id: string }) => {
    setSelectedVehicleId(vehicle.id);
    setOpenDialog(true);
  };

  const renderDeleteButton = (props: GridRenderCellParams<GridValidRowModel, string>) => {
    return (
      <Tooltip title="Usuń pojazd">
        <IconButton color='error' onClick={() => handleClickOpenDialog(props as unknown as (Vehicle & { id: string }))}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>

    );
  };

  const renderNotifcationButton = (vehicleId: string) => {
    return (
      <Tooltip title="Przejdź do powiadomień">
        <IconButton color='default' onClick={() => user && navigate(RoutesVars.COMPANY_VEHICLE_NOTIFICATION(user.companyId, vehicleId))}>
          <ForwardToInboxIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const renderEditButton = (props: GridRenderCellParams<GridValidRowModel, string>) => {
    return (
      <Tooltip title="Edytuj pojazd">
        <IconButton color='primary' onClick={() => user && navigate(RoutesVars.COMPANY_VEHICLE_DETAILS(user.companyId, props.id))}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'licensePlate', headerName: 'Numer rej.', flex: 12, headerAlign: 'center', align: 'center' },
    { field: 'owner', headerName: 'Właściciel', flex: 12, headerAlign: 'center', align: 'center' },
    { field: 'brand', headerName: 'Marka', flex: 14, headerAlign: 'center', align: 'center' },
    { field: 'model', headerName: 'Model', flex: 14, headerAlign: 'center', align: 'center' },
    {
      field: 'type', headerName: 'Rodzaj', headerAlign: 'center', align: 'center', flex: 20,
      renderCell: (params: GridRenderCellParams<GridValidRowModel, string>) => VehicleTypeTag(params.row.type)
    },
    { field: 'mileage', headerName: 'Aktualny przebieg (km)', type: 'number', headerAlign: 'center', align: 'center', flex: 18 },
    {
      field: 'notifcationButton', headerName: 'Powiadomienia', headerAlign: 'center', align: 'center', flex: 3,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, Vehicle>) => renderNotifcationButton(params.row.id),
    },
    {
      field: 'editButton', headerName: 'Edytuj Pojazd', headerAlign: 'center', align: 'center', flex: 3,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, Vehicle>) => renderEditButton(params.row),
    },
    {
      field: 'deleteButton', headerName: 'Usuń Pojazd', headerAlign: 'center', align: 'center', flex: 2,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, Vehicle>) => renderDeleteButton(params.row),
    },
  ];

  const tableRows = useMemo(() => vehicles.map((vehicle) => {
    const { licensePlate, brand, model, type, vehicleCard, id, owner } = vehicle;
    return createRowData(id, licensePlate, owner, brand, model, type, vehicleCard.mileage, vehicle);
  }), [vehicles]);

  return (
    <div style={{ height: '90vh', width: '100%' }}>
      <DataGrid
        rows={tableRows}
        columns={columns}
        loading={isLoading} // TODO: Handle empty state
        checkboxSelection
        slotProps={{
          toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
        }}
        slots={{
          noRowsOverlay: () => TableEmptyState({ text: 'Brak pojazdów do wyświetlenia, dodaj nowe...', isButtonHidden: true }),
          toolbar: GridToolbar
        }}
        initialState={{
          pagination: { paginationModel: { page: 0, pageSize: 30 } },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        pageSizeOptions={[5, 10, 20, 30, 50, 100]}
        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
      />

      <AlertDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleCloseConfirmaton={handleCloseDialog}
        title='Usuń pojazd'
        content='Jesteś pewny że chcesz usunąć pojazd? Zmiany nie można cofnąć!'
        approvalActionText='Usuń'
        cancelActionText='Odrzuć'
      />
    </div>
  );
};

export default AllVehiclesTable;