/* eslint-disable max-len */
import React, { FC } from 'react';
import { Box, CardMedia, Grid, Typography, styled } from '@mui/material';

import cardIcon1 from '../../../../assets/images/cardIcon1.png';
import cardIcon2 from '../../../../assets/images/cardIcon2.png';
import cardIcon3 from '../../../../assets/images/cardIcon3.png';
import cardIcon4 from '../../../../assets/images/cardIcon4.png';
import cardBackground1 from '../../../../assets/images/cardBackground1.png';
import cardBackground2 from '../../../../assets/images/cardBackground2.png';
import cardBackground3 from '../../../../assets/images/cardBackground3.png';
import cardBackground4 from '../../../../assets/images/cardBackground4.png';

const items = [
  {
    title: 'Wygoda',
    icon: cardIcon1,
    backgroundImage: cardBackground1,
    text: 'Skoordynowane zarządzanie i monitoring terminów oraz danych pojazdów sprzyja efektywności i kontroli działań.'
  },
  {
    title: 'Kontrola',
    icon: cardIcon2,
    backgroundImage: cardBackground2,
    text: 'Dzięki systemowi powiadomień ryzyko opóźnień w dokumentacji i przeglądach jest minimalizowane, co zmniejsza koszty związane z utrzymaniem floty pojazdów.'
  },
  {
    title: 'Bezpieczeństwo',
    icon: cardIcon3,
    backgroundImage: cardBackground3,
    text: 'Regularne przypomnienia o konieczności przeglądów i wymiany części zapewniają sprawność techniczną pojazdów, co wpływa na ich bezpieczeństwo i trwałość.'
  },
  {
    title: 'Niezawodność',
    icon: cardIcon4,
    backgroundImage: cardBackground4,
    text: 'Systematyczne przeglądy i serwisowanie są kluczowe dla firm transportowych, minimalizując ryzyko awarii i utrzymując ciągłość działania, co jest kluczowe dla utrzymania dochodów.',
  },
];

export const GridItemBackground = styled(Grid)<{ backgroundimage: string }>(({ backgroundimage }) => ({
  backgroundImage: `url(${backgroundimage})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: 'center center',
  textAlign: 'center',
  color: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '15px',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
}));

const BenefitsSection: FC = () => (
  <Grid
    container
    justifyContent="center"
    columns={{ xs: 4, sm: 120, md: 120, lg: 120, xl: 120 }}
    sx={{
      px: { xs: 2.5, sm: 2.5, md: 2.5, lg: 5 },
      mb: 9,
    }}
  >
    {items.map((item) => (
      <GridItemBackground
        item xs={4} sm={58} md={58} lg={58} xl={29} key={item.title}
        backgroundimage={item.backgroundImage}
        sx={{
          height: { xs: 500, sm: 540, md: 540, lg: 555 },
          maxWidth: { lg: 560 },
          mr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1.5 },
          mb: { xs: 1, sm: 1, md: 1, lg: 1 },
          p: { xs: 2, sm: 2, md: 2, lg: 6, xl: 3 },
        }}
      >
        <Box sx={{}}>
          <CardMedia
            component="img"
            image={item.icon}
            sx={{
              height: { xs: 97, sm: 107, md: 'auto', lg: 'auto', xl: 97 },
              width: { xs: 99, sm: 109, md: 'auto', lg: 'auto', xl: 99 },
              mb: 1
            }}
          />
        </Box>
        <Box>
          <Typography sx={{
            fontSize: { xs: 30, sm: 30, md: 30, lg: 34 },
            my: { xs: 1, lg: 2 }
          }}>
            {item.title}
          </Typography>
          <Typography sx={{ fontSize: { xs: 20, sm: 20, md: 20, lg: 24, xl: 24 } }}>
            {item.text}
          </Typography>
        </Box>
      </GridItemBackground>
    ))}
  </Grid >
);

export default BenefitsSection;

