import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type Props = {
  open: boolean,
  handleClose: () => void,
  handleCloseConfirmaton: () => void,
  handleCloseAndCreateConfirmaton?: () => void,
  title: string;
  content: string;
  cancelActionText: string;
  approvalActionText: string;
  eventToEnd?: string;
};

const AlertDialog: React.FC<Props> = ({
  open,
  title,
  content,
  cancelActionText,
  approvalActionText,
  eventToEnd,
  handleClose,
  handleCloseConfirmaton,
  handleCloseAndCreateConfirmaton
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{ fontWeight: 'bold' }}>{cancelActionText}</Button>
        {!!eventToEnd && (
          <Button onClick={handleCloseAndCreateConfirmaton} autoFocus color='warning' variant='contained' cy-testId="Button-recreate">
            Zakończ i stwórz podobny
          </Button>
        )}
        <Button onClick={handleCloseConfirmaton} autoFocus color='error' variant='contained' cy-testId="Button-approvalAction">
          {approvalActionText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;