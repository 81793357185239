import { VehicleEvent } from "../../../interfaces";

export function createRowData(
  id: string,
  vehicleId: string,
  typeMessage: string,
  name: string,
  licensePlateExtended: string,
  start: Date,
  finish: Date | null,
  status: string,
  endMileage: number | null,
  type: string,
  description: string,
  renderEditButton: VehicleEvent,
  renderDeleteButton: VehicleEvent,
): Partial<VehicleEvent> & { renderEditButton: VehicleEvent; renderDeleteButton: VehicleEvent; } {
  return {
    id,
    vehicleId,
    typeMessage,
    name,
    licensePlateExtended,
    start,
    finish,
    status,
    endMileage,
    type,
    description,
    renderEditButton,
    renderDeleteButton,
  };
}
