import * as React from 'react';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import MobileContent from './MobileContent/MobileContent';
import DesktopContent from './DesktopContent/DesktopContent';
import { colorsTheme } from '../../../themes/theme';

const FooterContainer = styled('footer')(() => ({
  padding: 20,
  backgroundColor: colorsTheme.primary.main
}));

const Footer: React.FunctionComponent = () => (
  <FooterContainer>
    <Stack sx={{ display: { xs: 'flex', sm: 'none' } }}>
      <MobileContent />
    </Stack>

    <Stack sx={{ display: { xs: 'none', sm: 'flex' } }}>
      <DesktopContent />
    </Stack>
  </FooterContainer>
);

export default Footer;

