import React from 'react';
import { Button, MenuItem, Select, TextField, Typography } from '@mui/material';
import { FormikErrors, useFormik } from 'formik';

import { Vehicle } from '../../interfaces';
import { VehicleTypeOptions } from '../../const/constVehicle';

interface Props {
  formSubmit: (vehicle: Vehicle) => void;
  submitText: string;
  vehicle?: Vehicle;
}

const defaultVehicle = {
  licensePlate: '',
  brand: '',
  model: '',
  type: '',
  vehicleCard: { mileage: 0 },
  id: '',
  vin: '',
  yearOfManufacture: '',
  owner: '',
};

const VehicleInformationForm: React.FC<Props> = ({ vehicle = defaultVehicle, formSubmit, submitText }) => {

  const formik = useFormik({
    initialValues: {
      licensePlate: vehicle.licensePlate,
      brand: vehicle.brand,
      model: vehicle.model,
      type: vehicle.type,
      vehicleCard: { mileage: vehicle.vehicleCard.mileage },
      id: vehicle.id,
      vin: vehicle.vin || '',
      yearOfManufacture: vehicle.yearOfManufacture || '',
      owner: vehicle.owner || '',
    },
    onSubmit: (values) => {
      const vehicle: Vehicle = {
        licensePlate: values.licensePlate,
        brand: values.brand,
        model: values.model,
        type: values.type,
        vehicleCard: { mileage: values.vehicleCard.mileage },
        id: values.id,
        vin: values.vin,
        yearOfManufacture: values.yearOfManufacture,
        owner: values.owner,
      };
      formSubmit(vehicle);
    },
    validate(values: Vehicle) {
      const errors: FormikErrors<Vehicle> = {};
      console.log(values);
      // if (!values.brand) {
      //   errors.brand = 'Required!';
      // }

      // if (!values.brand) {
      //   errors.brand = 'Required!';
      // eslint-disable-next-line max-len
      // } else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.brand)) {
      //   errors.brand = 'Invalid email format!';
      // }
      // return errors;
    },
    // validate(values) {
    //   const errors: FormikErrors<User> = {};

    //   if (!values.name) {
    //     errors.name = 'Required';
    //   } else if (!/^[A-Z][a-z0-9_-]{3,19}$/.test(values.name)) {
    //     errors.name = 'Invalid email address';
    //   }
    //   return errors;
    // },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        disabled
        fullWidth
        id="licensePlate"
        name="licensePlate"
        label="Numer rejestracyjny"
        value={formik.values.licensePlate}
        onChange={formik.handleChange}
        error={formik.touched.licensePlate && Boolean(formik.errors.licensePlate)}
        helperText={formik.touched.licensePlate && formik.errors.licensePlate}
        sx={{ marginBottom: '12px' }}
      />
      <TextField
        fullWidth
        // required
        id="brand"
        name="brand"
        label="Marka pojazdu"
        value={formik.values.brand}
        onChange={formik.handleChange}
        error={formik.touched.brand && Boolean(formik.errors.brand)}
        helperText={formik.touched.brand && formik.errors.brand && <div>{formik.errors.brand}</div>}
        sx={{ marginBottom: '12px' }}
      />
      <TextField
        fullWidth
        id="model"
        name="model"
        label="Model pojazdu"
        value={formik.values.model}
        onChange={formik.handleChange}
        error={formik.touched.model && Boolean(formik.errors.model)}
        helperText={formik.touched.model && formik.errors.model}
        sx={{ marginBottom: '12px' }}
      />
      <TextField
        fullWidth
        id="yearOfManufacture"
        name="yearOfManufacture"
        label="Rok produkcji"
        value={formik.values.yearOfManufacture}
        onChange={formik.handleChange}
        error={formik.touched.yearOfManufacture && Boolean(formik.errors.yearOfManufacture)}
        helperText={formik.touched.yearOfManufacture && formik.errors.yearOfManufacture}
        sx={{ mb: '12px' }}
      />
      <TextField
        fullWidth
        id="vin"
        name="vin"
        label="VIN"
        value={formik.values.vin}
        onChange={formik.handleChange}
        error={formik.touched.vin && Boolean(formik.errors.vin)}
        helperText={formik.touched.vin && formik.errors.vin}
        sx={{ mb: '12px' }}
      />
      <TextField
        fullWidth

        id="owner"
        name="owner"
        label="Właściciel"
        value={formik.values.owner}
        onChange={formik.handleChange}
        error={formik.touched.owner && Boolean(formik.errors.owner)}
        helperText={formik.touched.owner && formik.errors.owner}
        sx={{ mb: '12px' }}
      />
      <Select
        fullWidth
        required
        id="type"
        name="type"
        label="Rodzaj pojazdu"
        value={formik.values.type}
        onChange={formik.handleChange}
        sx={{ marginBottom: '12px' }}
      >
        {VehicleTypeOptions.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            {option.name}
          </MenuItem>
        ))}
      </Select>

      <Typography sx={{ mt: 3, mb: 2, textAlign: 'center', color: 'black', fontSize: 18, fontStyle: 'italic' }}>
        Aktualny przebieg pojazdu:
        <strong> {vehicle.vehicleCard.mileage.toLocaleString('pl-PL')} km</strong>
      </Typography>

      <Button
        color="primary"
        variant="contained"
        type="submit"
        fullWidth
      >
        {submitText}
      </Button>
    </form>
  );
};

VehicleInformationForm.defaultProps = {
  vehicle: {
    licensePlate: '',
    brand: '',
    model: '',
    type: '',
    vehicleCard: { mileage: 0 },
    id: '',
    vin: '',
    yearOfManufacture: '',
    owner: '',
  },
};

export default VehicleInformationForm;
