import React from 'react';
import { Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const CopyrightElementContainer = styled(Typography)(() => ({
  fontSize: '12px',
  padding: '0 10px',
  color: 'whitesmoke',
}));

const CopyrightElement = (): React.ReactElement => (
  <CopyrightElementContainer>
    Copyright
    {' '}
    {new Date().getFullYear()}
    {' '}
    <Link
      color="inherit"
      href="/"
      sx={{ textDecoration: 'none' }}
      aria-label="Przejdź do strony głownej"
    >
      Kieruj Flotą
    </Link>
  </CopyrightElementContainer>
);

export default CopyrightElement;
