import React, { useContext } from 'react';
import { Chip, Tooltip } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import SimCardAlertOutlinedIcon from '@mui/icons-material/SimCardAlertOutlined';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';

import { EventsStatus, EventsStatusKeys } from '../../../const/constNotifications';
import { VehicleEvent } from '../../../interfaces';
import { updateVehicleEvent } from '../../../api/services/VehicleEventService';
import { UserContext } from '../../../contexts/UserContext/UserContext';

const EventStatusTag = (props: VehicleEvent) => {
  const queryClient = useQueryClient();
  const { state: { user } } = useContext(UserContext);

  const updateMutation = useMutation({
    mutationFn: (event: Partial<VehicleEvent>) => updateVehicleEvent(props.vehicleId, event),
    onSuccess: () => {
      queryClient.invalidateQueries(['getVehicleNotifications', user?.id, props.vehicleId]);
      queryClient.invalidateQueries(['getEvents', user?.id]);
    }
  });

  const icon = () => {
    switch (props.status) {
      case EventsStatusKeys.ACTIVE: return <NotificationsActiveOutlinedIcon
        color='success'
        sx={{ '&:hover': { cursor: 'pointer' } }}
        onClick={() => updateMutation.mutate({
          name: props.name,
          start: props.start,
          finish: props.finish,
          status: EventsStatusKeys.PAUSED,
          endMileage: props.endMileage,
          id: props.id
        })}
      />;
      case EventsStatusKeys.FINISHED: return <NotificationsOffOutlinedIcon color='inherit' />;
      case EventsStatusKeys.PAUSED: return <PauseCircleOutlineIcon
        color='warning'
        sx={{ '&:hover': { cursor: 'pointer' } }}
        onClick={() => updateMutation.mutate({
          name: props.name,
          start: props.start,
          finish: props.finish,
          status: EventsStatusKeys.ACTIVE,
          endMileage: props.endMileage,
          id: props.id
        })}
      />;

      default: return <SimCardAlertOutlinedIcon color='inherit' />;
    }
  };

  const style = () => {
    const common = { fontWeight: 'bold', borderRadius: '3px' };
    switch (props.status) {
      case EventsStatusKeys.ACTIVE:
        return {
          ...common,
          backgroundColor: '#DFE1E6',
          color: '#42526E',
        };

      case EventsStatusKeys.FINISHED:
        return {
          ...common,
          backgroundColor: '#F4F5F7',
          color: '#42526E',
        };

      case EventsStatusKeys.PAUSED:
        return {
          ...common,
          backgroundColor: '#F4F5F7',
          color: '#42526E',
        };

      default:
        return {
          ...common,
          backgroundColor: '#DEEBFF',
          color: '#2684FF',
        };
    }
  };

  return (
    <Tooltip title={EventsStatus[props.status as keyof typeof EventsStatus]}>
      <Chip
        label={EventsStatus[props.status as keyof typeof EventsStatus]}
        icon={icon()}
        sx={style()}
      />
    </Tooltip>
  );
};

export default EventStatusTag;