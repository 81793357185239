import React, { FC, useMemo } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar, GridValidRowModel, plPL } from '@mui/x-data-grid';

import { VehicleEvent } from '../../../interfaces';
import { createRowData } from './utils';
import TableEmptyState from '../../../components/TableEmptyState';
import { getSelectedRowsToExport } from '../../../utils/tables/utils';

type Props = {
  events: VehicleEvent[];
  isLoading: boolean;
};

const EventsHistoryTable: FC<Props> = ({ events, isLoading }) => {
  const renderEndMileage = (endMileage: number | null) => {
    const displayEndMilage = (endMileage === 0 || endMileage) ? endMileage : '-';
    return (
      <div>{displayEndMilage}</div>
    );
  };

  const renderFinish = (finish: string | null) => {
    const displayFinish = finish || '-';
    return (
      <div>{displayFinish}</div>
    );
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID Powiadomienia', flex: 10 },
    { field: 'name', headerName: 'Powiadomienie', flex: 20 },
    { field: 'licensePlateExtended', headerName: 'Pojazd', flex: 20 },
    {
      field: 'finish', headerName: 'Termin wykonania', headerAlign: 'center', align: 'center', flex: 20,
      renderCell: (params: GridRenderCellParams<GridValidRowModel, string>) => renderFinish(params.row.finish),
    },
    { field: 'start', headerName: 'Data dodania', headerAlign: 'center', align: 'center', flex: 20 },
    {
      field: 'endMileage', headerName: 'Limit (km)', headerAlign: 'center', align: 'center', flex: 20,
      renderCell: (params: GridRenderCellParams<GridValidRowModel, string>) => renderEndMileage(params.row.endMileage),
    },
  ];

  const tableRows = useMemo(() => events.map((event) => {
    const { id, name, licensePlateExtended, start, finish } = event;
    return createRowData(id, name, licensePlateExtended, start, finish);
  }), [events]);

  return (
    <div style={{ width: '100%', height: '78vh' }}>
      <DataGrid
        rowSelection={false}
        rows={tableRows}
        columns={columns}
        loading={isLoading}
        checkboxSelection
        slotProps={{
          toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
        }}
        initialState={{
          pagination: { paginationModel: { page: 0, pageSize: 30 } },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        slots={{
          noRowsOverlay: () => TableEmptyState({ text: 'Brak powiadomień do wyświetlenia...', isButtonHidden: true }),
          toolbar: GridToolbar
        }}
        pageSizeOptions={[5, 10, 20, 30, 50, 100]}
        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
      />
    </div>
  );
};

export default EventsHistoryTable;