export enum Chanel {
  EMAIL = 'Email',
  SMS = 'Sms'
}
export const ChanelOptions = [
  {name: Chanel.EMAIL, key: Object.keys(Chanel)[0] },
  {name: Chanel.SMS, key: Object.keys(Chanel)[1]},
];

export enum FrequencyOption {
  STANDARD = 'Standard',
}
export const FrequencyOptionOptions = [
  {name: FrequencyOption.STANDARD, key: Object.keys(FrequencyOption)[0] },
];

export enum EventsNameDefaults {
  OLI_CHANGE = 'Wymiana oleju',
  INSURANCE = 'Ubezpieczenie OC-AC',
  TECHNICAL_CONTROL = 'Badanie techniczne',
  TACHOGRAPH_LEGALIZATION = 'Legalizacja tachografu',
  BRAKE_PADS_CHANGE = 'Wymiana klocków hamulcowych',
}
export const EventsNameDefaultsOptions = [
  {name: EventsNameDefaults.OLI_CHANGE, key: Object.keys(EventsNameDefaults)[0] },
  {name: EventsNameDefaults.INSURANCE, key: Object.keys(EventsNameDefaults)[1]},
  {name: EventsNameDefaults.TECHNICAL_CONTROL, key: Object.keys(EventsNameDefaults)[2]},
  {name: EventsNameDefaults.TACHOGRAPH_LEGALIZATION, key: Object.keys(EventsNameDefaults)[3]},
  {name: EventsNameDefaults.BRAKE_PADS_CHANGE, key: Object.keys(EventsNameDefaults)[4]},
];

export enum NotificationStatus {
  ON = "ON",
  OFF = 'OFF'
}
export const NotificationStatusOptions = [
  {name: NotificationStatus.ON, key: Object.keys(NotificationStatus)[0] },
  {name: NotificationStatus.OFF, key: Object.keys(NotificationStatus)[1]},
];

export enum EventsType {
  AFTER_DEAD_LINE_MILEAGE = 'Po terminie',
  AFTER_DEAD_LINE_DATE = 'Po terminie',
  SMALL_MILEAGE = 'Mniej niż 1000km',
  SMALL_DATE = 'Mniej niż 7 dni',
  MID_MILEAGE = 'Mniej niż 3000km',
  MID_DATE = 'Mniej niż 14 dni',
  LARGE_MILEAGE = 'Powyżej 7000km',
  LARGE_DATE = 'Powyżej miesiąca',
  WITHOUT_ALERT = 'Bez powiadomienia',
  CURRENT_DATE = 'Dzisiaj',
}
export const EventsTypeOptions = [
  {name: EventsType.AFTER_DEAD_LINE_MILEAGE, key: Object.keys(EventsType)[0] },
  {name: EventsType.AFTER_DEAD_LINE_DATE, key: Object.keys(EventsType)[1]},
  {name: EventsType.SMALL_MILEAGE, key: Object.keys(EventsType)[2]},
  {name: EventsType.SMALL_DATE, key: Object.keys(EventsType)[3]},
  {name: EventsType.MID_MILEAGE, key: Object.keys(EventsType)[4]},
  {name: EventsType.MID_DATE, key: Object.keys(EventsType)[5]},
  {name: EventsType.LARGE_MILEAGE, key: Object.keys(EventsType)[6]},
  {name: EventsType.LARGE_DATE, key: Object.keys(EventsType)[7]},
  {name: EventsType.WITHOUT_ALERT, key: Object.keys(EventsType)[8]},
  {name: EventsType.CURRENT_DATE, key: Object.keys(EventsType)[9]},
];
export enum EventsTypeKeys {
  AFTER_DEAD_LINE_MILEAGE = 'AFTER_DEAD_LINE_MILEAGE',
  AFTER_DEAD_LINE_DATE = 'AFTER_DEAD_LINE_DATE',
  SMALL_MILEAGE = 'SMALL_MILEAGE',
  SMALL_DATE = 'SMALL_DATE',
  MID_MILEAGE = 'MID_MILEAGE',
  MID_DATE = 'MID_DATE',
  LARGE_MILEAGE = 'LARGE_MILEAGE',
  LARGE_DATE = 'LARGE_DATE',
  WITHOUT_ALERT = 'WITHOUT_ALERT',
  CURRENT_DATE = 'CURRENT_DATE',
}

export enum EventsStatus {
  ACTIVE = 'Aktywny',
  FINISHED = 'Zakończony',
  PAUSED = 'Wstrzymany',
}
export const EventsStatusOptions = [
  {name: EventsStatus.ACTIVE, key: Object.keys(EventsStatus)[0] },
  {name: EventsStatus.FINISHED, key: Object.keys(EventsStatus)[1]},
  {name: EventsStatus.PAUSED, key: Object.keys(EventsStatus)[2]},
];
export enum EventsStatusKeys {
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  PAUSED = 'PAUSED',
}
