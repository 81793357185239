import * as React from 'react';
import { Autocomplete, Button, Chip, Switch, TextField, Typography, Box, FormControlLabel } from '@mui/material';
import { useQueryClient, useMutation } from '@tanstack/react-query';

import { Vehicle, VehicleNotification, VehicleNotificationCreation } from '../../../../interfaces';
import AlertDialog from '../AlertDialog';
import { deleteVehicleNotification, updateVehicleNotification } from '../../../../api/services/VehicleNotificationService';
import theme from '../../../../themes/theme';
import { NotificationStatus } from '../../../../const/constNotifications';
import { UserContext } from '../../../../contexts/UserContext/UserContext';
import { toast } from 'react-hot-toast';

type Props = {
  vehicle: Vehicle;
  vehicleNotification: VehicleNotification;
  refetch: () => void;
};

const NotificationSettings: React.FC<Props> = ({ vehicle, vehicleNotification, refetch }) => {
  const { state: { user } } = React.useContext(UserContext);
  const queryClient = useQueryClient();

  const [open, setOpen] = React.useState(false);
  const [contactEmails, setContactEmails] = React.useState(vehicleNotification.receiversContact);
  const [notificationStatus, setNotificationStatus] = React.useState(vehicleNotification.status);

  const deleteMutation = useMutation({
    mutationFn: () => deleteVehicleNotification(vehicle.id),
    onSuccess: () => {
      refetch();
      queryClient.invalidateQueries(['getVehicleNotifications', user?.id, vehicle.id]);
      toast.success('Poprawnie usunięto powiadomienia.');
    },
    onError: () => { toast.error('Błąd podczas usuwania powiadomienia.'); },
    meta: { hideDefaultToast: true }
  });

  const updateMutation = useMutation({
    mutationFn: (updatedNotification: Partial<VehicleNotificationCreation>) => updateVehicleNotification(vehicle.id, updatedNotification),
    onSuccess: () => {
      queryClient.invalidateQueries(['getVehicleNotifications', user?.id, vehicle.id]);
      toast.success('Poprawnie wyciszono powiadomienia.');
    },
    onError: () => { toast.error('Błąd podczas wyciszania powiadomienia.'); },
    meta: { hideDefaultToast: true }
  });

  const handleChangeContactEmails = (newValue: string[]) => {
    updateMutation.mutate({ receiversContact: newValue });
  };

  const toggleNotificationStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const status = event.target.checked ? NotificationStatus.ON : NotificationStatus.OFF;
    setNotificationStatus(status);
    updateMutation.mutate({ status: status });
  };

  return (
    <Box sx={{ maxHeight: 100 }}>
      <Typography sx={{ fontStyle: 'italic' }}>
        Numer rejestracyjny:
        <strong> {vehicle.licensePlate}</strong>
      </Typography>
      <Typography sx={{ fontStyle: 'italic', mt: 1 }}>
        Aktualny przebieg:
        <strong> {vehicle.vehicleCard.mileage.toLocaleString('pl-PL')} km</strong>
      </Typography>

      <Typography sx={{ fontStyle: 'italic' }}>
        Wycisz powiadomienia:
        <FormControlLabel
          control={
            <Switch
              color="success"
              checked={notificationStatus === NotificationStatus.ON}
              onChange={toggleNotificationStatus}
              sx={{ ml: 1 }}
            />}
          defaultChecked
          color="success"
          label=""
        />
        <strong>{notificationStatus}</strong>
      </Typography>

      <Typography sx={{ fontStyle: 'italic' }}>
        Usuń powiadomienia:
        <Button variant="contained" color="error" size='small' onClick={() => setOpen(true)} sx={{ ml: 1, p: 0, fontSize: 12 }}>
          Usuń
        </Button>
      </Typography>

      <Typography sx={{ fontStyle: 'italic', mt: 1 }}>
        <strong> Dodatkowi odbiorcy powiadomień:</strong>
      </Typography>
      <Autocomplete
        multiple
        limitTags={1}
        options={[]}
        defaultValue={[...contactEmails]}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              key={option}
              sx={{ fontSize: 14 }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size='small'
            placeholder='Dodaj...'
            sx={{ "& fieldset": { flexWrap: 'nowrap' }, "& .MuiAutocomplete-tag": { maxWidth: 220 } }}
          />
        )}
        value={contactEmails}
        onChange={(event, newValue: string[]) => { setContactEmails(newValue); handleChangeContactEmails(newValue); }}
        sx={{ fontStyle: 'italic', backgroundColor: theme.palette.grey[100] }}
      />

      <AlertDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleCloseConfirmaton={deleteMutation.mutate}
        title='Usuń system powiadomień'
        content='Jesteś pewny ze chcesz usunąć wszyskie powiadomienia? Zmian nie można cofnąć!'
        approvalActionText='Usuń'
        cancelActionText='Odrzuć'
      />
    </Box>
  );
};

export default NotificationSettings;

