/* eslint-disable max-len */
import axios, { AxiosResponse } from 'axios';

import apiUrl from './routes';
import { getToken, isAuthenticated, refreshToken } from '../contexts/Auth/authUtils';
import { refreshAccessToken } from './services/AccountService';

const axiosLogin = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

const axiosApi = axios.create({
  baseURL: apiUrl,
  headers: { 'Content-Type': 'application/json' },
});

const tokenExpired = (response: AxiosResponse): boolean => {
  const isAuthenticatedUser = isAuthenticated();

  if (!response) return false;
  if (response.status !== 401) return false;
  // if (response.data && isAuthenticatedUser) return true;
  if (response.data === '' && isAuthenticatedUser) return true; //TODO: do zmiany nie moze byc  '' tylko mess z be
    // if (response.data && response.data.detail === TOKEN_EXPIRED_MESSAGE) return true; TODO: do zmiany

  return false;
};

axiosApi.interceptors.request.use((request) => {
  const token = getToken();

  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
});

axiosApi.interceptors.response.use(
  response => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (!originalRequest) return Promise.reject(error);
    if (!originalRequest.request_retried && tokenExpired(error.response)) {
      originalRequest.request_retried = true;

      const result = await refreshAccessToken();
      if (result.status === 200) {
        refreshToken(result.data);
      }

      return axiosApi(originalRequest);
    }

    return Promise.reject(error);
  }
);

export {
  axiosLogin,
  axiosApi,
};