import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { UserContext } from '../../contexts/UserContext/UserContext';
import { isAuthenticated } from '../../contexts/Auth/authUtils';

type PrivateRouteProps = { children: JSX.Element };

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { actions: { fetchUser }, state: { user } } = React.useContext(UserContext);
  const location = useLocation();
  const isAuthenticatedUser = isAuthenticated();
  // const { id } = useParams();

  // console.log('id', id);
  // console.log('user', user?.id);// //TODO -> to verify if user manipulate ulr's IDs

  useEffect(() => {
    if (isAuthenticatedUser && !user) fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticatedUser, user]);

  // if (id !== user?.id) {
  // return <Navigate to="/login" state={{ from: location }} replace />;
  // } //TODO: inf looooooop now ....

  return isAuthenticatedUser ? children : <Navigate to="/login" state={{ from: location }} />;
};

export default PrivateRoute;
