import { Vehicle, VehicleCreate } from "../../interfaces";
import { axiosApi } from "../axios";
import { routes } from "../routes";

const getVehicles = async () => {
  const response = await axiosApi.get<Vehicle[]>(routes.VEHICLES);
  return response.data;
};

const getVehicle = async (id: string) => {
  const response = await axiosApi.get<Vehicle>(routes.VEHICLE(id));
  return response.data;
};

const addVehicle = async (vehicle: VehicleCreate) => {
  const response = await axiosApi.post<Vehicle>(routes.VEHICLES, vehicle);
  return response.data;
};

const updateVehicle = async (id: string, vehicle: Vehicle) => { // here Partial - not all needed
  const response = await axiosApi.patch<Vehicle>(routes.VEHICLE(id), vehicle);
  return response.data;
};

const deleteVehicle = async (id: string) => {
  const response = await axiosApi.delete(routes.VEHICLE(id));
  return response.data;
};

export {
  getVehicles,
  getVehicle,
  addVehicle,
  updateVehicle,
  deleteVehicle,
};