import { VehicleEvent } from "../../../interfaces";

export function createRowData(
  id: string,
  name: string,
  licensePlateExtended: string,
  start: Date,
  finish: Date | null,
): Partial<VehicleEvent> {
  return {
    id,
    name,
    licensePlateExtended,
    start,
    finish,
  };
}
