import { Dispatch } from 'react';

import { FETCH_USER, FETCH_USER_ERROR, LOGOUT_USER, ReducerAction } from "./UserReducer";
import { getUser } from '../../api/services/AccountService';
import { deleteToken } from '../Auth/authUtils';

export type Action = (dispatch: Dispatch<ReducerAction>) => Promise<void>;

export const fetchUser: Action = async (disptach: Dispatch<ReducerAction>) => {
  try {
    const result = await getUser();
    const payload = result.data;

    disptach({ type: FETCH_USER, payload: payload });
  } catch {
    disptach({ type: FETCH_USER_ERROR, payload: null });
  }
};

export const logoutUser = (dispatch: Dispatch<ReducerAction>): void => {
  deleteToken();
  dispatch({
    type: LOGOUT_USER,
  });
};

const actions = {
  fetchUser,
  logoutUser,
};

export default actions;

