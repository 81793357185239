import { VehicleEvent, VehicleEventCreate } from "../../interfaces";
import { axiosApi } from "../axios";
import { routes } from "../routes";

const getEvent = async (eventId: string) => {
  const response = await axiosApi.get<VehicleEvent>(routes.GET_VEHICLE_EVENT(eventId));
  return response.data;
};

const getEvents = async () => {
  const response = await axiosApi.get<VehicleEvent>(routes.VEHICLE_EVENTS);
  return response.data;
};

const getEventsHistory = async () => {
  const response = await axiosApi.get<VehicleEvent>(routes.VEHICLE_EVENTS_HISTORY);
  return response.data;
};

const addVehicleEvent = async (vehicleId: string, event: VehicleEventCreate) => {
  const response = await axiosApi.post<VehicleEventCreate>(routes.ADD_VEHICLE_EVENT(vehicleId), event);
  return response.data;
};

const updateVehicleEvent = async (vehicleId: string, event: Partial<VehicleEvent>) => {
  const response = await axiosApi.put<VehicleEventCreate>(routes.VEHICLE_EVENT(vehicleId, event.id), event);
  return response.data;
};

const deleteVehicleEvent = async (vehicleId: string, eventId: string) => {
  const response = await axiosApi.delete(routes.VEHICLE_EVENT(vehicleId, eventId));
  return response.data;
};

export {
  getEvent,
  getEvents,
  getEventsHistory,
  addVehicleEvent,
  deleteVehicleEvent,
  updateVehicleEvent,
};
