import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';

function ContactForms(): JSX.Element {

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID as string,
        process.env.REACT_APP_TEMPLATE_ID as string,
        e.currentTarget,
        process.env.REACT_APP_PUBLIC_KEY as string
      ).then(
        () => { toast.success('Poprawnie przesłano formularz kontaktowy.'); },
        () => { toast.error('Błąd podczas przesyłania formularza kontaktowego.'); }
      );
    e.currentTarget.reset();
  };

  const formik = useFormik({
    initialValues: {
      company: '',
      name: '',
      email: '',
      telephone: '',
      comment: '',
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => { },
  });

  return (
    <Box>
      <form onSubmit={(e) => { sendEmail(e); }}>
        <TextField
          fullWidth
          variant='standard'
          id="company"
          name="company"
          label="Nazwa firmy"
          type="text"
          value={formik.values.company}
          onChange={formik.handleChange}
          sx={{ mb: '5px' }}
        />
        <TextField
          fullWidth
          variant='standard'
          id="name"
          name="name"
          label="Imię i nazwisko"
          type="text"
          value={formik.values.name}
          onChange={formik.handleChange}
          sx={{ mb: '5px' }}
        />
        <TextField
          required
          fullWidth
          variant='standard'
          id="email"
          name="email"
          label="Adres email"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          sx={{ mb: '5px' }}
        />
        <TextField
          fullWidth
          variant='standard'
          id="telephone"
          type="tel"
          name="telephone"
          label="Numer telefonu"
          value={formik.values.telephone}
          onChange={formik.handleChange}
          sx={{ mb: '15px' }}
        />
        <TextField
          required
          fullWidth
          id="comment"
          name="comment"
          label="Twoja wiadomość..."
          type="text"
          multiline
          rows={2}
          value={formik.values.comment}
          onChange={formik.handleChange}
          sx={{ mb: '12px' }}
        />
        <Box display="flex" justifyContent="center" sx={{}}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            sx={{
              width: { xs: 150, sm: 180, md: 180, lg: 220 },
              height: { xs: 40, sm: 40, md: 40, lg: 50 },
              fontSize: { xs: 20, sm: 25, md: 25, lg: 30 },
              fontWeight: 500
            }}
          >
            Wyślij
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default ContactForms;

