import React, { FC, useContext } from 'react';
import { UserContext } from '../../contexts/UserContext/UserContext';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getVehicle, updateVehicle } from '../../api/services/VehicleService';
import { Vehicle } from '../../interfaces';
import { Box, LinearProgress, Typography } from '@mui/material';
import theme from '../../themes/theme';
import VehicleInformationForm from './VehicleInformationForm';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

const VehicleDetailsPage: FC = () => {
  const { state: { user } } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { vehicleId } = useParams();

  const { isLoading, isError, data: vehicle } = useQuery({
    queryKey: ['getVehicle', user?.id, vehicleId],
    queryFn: () => getVehicle(vehicleId as string),
    meta: { errorMessage: 'Błąd podczas ładowania pojazdu.' },
    retry: false,
  });

  const updateMutation = useMutation({
    mutationFn: (updatedVehicle: Vehicle) => updateVehicle(vehicleId as string, updatedVehicle),
    onSuccess: () => {
      queryClient.invalidateQueries(['getVehicle', user?.id, vehicleId]);
      toast.success('Poprawnie edytowano pojazd.');
    },
    onError: () => { toast.error('Błąd podczas edycji pojazdu.'); },
    meta: { hideDefaultToast: true }
  });

  const formSubmit = (updatedVehicle: Vehicle) => { updateMutation.mutate(updatedVehicle); };

  if (isError) return <div>Oppps coś poszło nie tak...</div>;
  if (isLoading || !vehicle) return <LinearProgress />;

  return (
    <Box sx={{ flexGrow: 1, overflow: 'auto', width: '100%', mt: 1, maxWidth: 1488, m: '0 auto', px: 2, pt: 2 }}>
      <Box sx={{
        border: '1.4px solid gray', width: '80%', maxWidth: 650, m: '0 auto', p: 4, borderRadius: '30px', backgroundColor: theme.palette.grey[100],
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      }}>
        <Typography sx={{ mb: 5, textAlign: 'center', color: 'black', fontSize: 26, fontStyle: 'italic' }}>
          Dane pojazdu:
          <strong> {vehicle?.licensePlate} </strong>
        </Typography>
        <VehicleInformationForm {...{ vehicle, formSubmit, submitText: 'Zatwierdź' }} />
      </Box>
    </Box>

  );
};

export default VehicleDetailsPage;