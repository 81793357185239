import React, { FC } from 'react';
import { Box, CardMedia, Typography } from '@mui/material';

import blueRectange from '../../../../assets/images/blueRectange.png';
import logo from '../../../../assets/images/logo.png';

const LogoSection: FC = () => (
  <Box
    maxWidth={1400}
    sx={{
      px: { xs: 2.5, sm: 2.5, md: 2.5, lg: 5 },
      m: '0px auto',
      mb: { xs: 2, sm: 3, md: 4, lg: 6, xl: 8 },
    }}>
    <Box
      flexGrow='1'
      sx={{
        backgroundImage: `url(${blueRectange})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: 'center center',
        py: { xs: 4, sm: 8, md: 8, lg: 8 },
        px: { xs: 3, sm: 8, md: 8, lg: 8 },
        width: '100%',
        borderRadius: '15px',
      }}
    >
      <Box
        sx={{
          px: { xs: 0, sm: 2, md: 8, lg: 8 },
          textAlign: 'center',
          color: '#FFFFFF',
        }}>
        <Box sx={{}}>
          <CardMedia
            component="img"
            image={logo}
            sx={{ height: 'auto', width: '100%', borderRadius: '5%', mb: 4 }}
          />
        </Box>
        <Typography sx={{ fontSize: { xs: 20, sm: 30, md: 40, lg: 60 }, fontWeight: 400 }}>
          Co zyskasz dzięki aplikacji?
        </Typography>
      </Box>
    </Box>

  </Box>
);

export default LogoSection;
