import React, { FC } from 'react';
import { Box, CardMedia, Grid, Typography, styled } from '@mui/material';

import benefitIcon1 from '../../../../assets/images/benefitIcon1.png';
import benefitIcon2 from '../../../../assets/images/benefitIcon2.png';
import benefitIcon3 from '../../../../assets/images/benefitIcon3.png';
import benefitIcon4 from '../../../../assets/images/benefitIcon4.png';
import benefitIcon5 from '../../../../assets/images/benefitIcon5.png';
import benefitIcon6 from '../../../../assets/images/benefitIcon6.png';
import { colorsTheme } from '../../../../themes/theme';

const items = [
  {
    icon: benefitIcon1,
    text: 'Otrzymuj powiadomienia o zbliżających się terminach dla każdego pojazdu. '
  },
  {
    icon: benefitIcon2,
    text: 'Możliwość konfiguracji powiadomień wedle  zapotrzebowania.'
  },
  {
    icon: benefitIcon3,
    text: 'Zapewnij kompatybilność z istniejącymi aplikacjami obsługiwanymi przez Twoją firmę.'
  },
  {
    icon: benefitIcon4,
    text: 'Śledź historię zakończonych notyfikacji w przejrzysty sposób.'
  },
  {
    icon: benefitIcon5,
    text: 'Skonfiguruj listę odbiorców dla każdego powiadomienia.'
  },
  {
    icon: benefitIcon6,
    text: 'Dostosuj powiadomienia według własnych preferencji i potrzeb.'
  },

];

export const GridItem = styled(Grid)(() => ({
  textAlign: 'center',
  color: '#040404',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const FeaturesSection: FC = () => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    columns={{ xs: 4, sm: 120, md: 120, lg: 120, xl: 120 }}
    sx={{
      px: { xs: 2.5, sm: 2.5, md: 2.5, lg: 5 },
      mb: { xs: 3, sm: 8, },
    }}
  >
    {items.map((item, index) => (
      <GridItem
        item xs={4} sm={58} md={58} lg={39} xl={18} key={`${item.icon}`}
        sx={{
          height: { xs: 270, sm: 320, md: 400, lg: 420, xl: 450 },
          maxWidth: { lg: 370 },
          mr: { xs: 0, sm: 1, md: 1, lg: 1, xl: 1.5 },
          mb: { xs: 1, sm: 1, md: 1, lg: 1 },
          p: { xs: 2, sm: 2, md: 2, lg: 2 },
          border: { sm: `2px solid ${colorsTheme.primary.main}` },
          borderBottom: {
            xs: index !== items.length - 1 ? `2px solid ${colorsTheme.primary.main}` : 'none'
          },
          borderRadius: { xs: 'none', sm: '15px' },
          boxShadow: {
            sx: 'none',
            sm: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          }
        }}
      >
        <Box display='flex' justifyContent='center'
          sx={{
            height: { xs: 90, sm: 100, md: 100, lg: 100 },
            width: { xs: '100%', sm: '100%', md: 'auto', lg: 'auto' },
            mb: 1
          }}>
          <CardMedia
            component="img"
            image={item.icon}
            sx={{ height: '100%', width: 'auto' }}
          />
        </Box>
        <Typography sx={{
          fontSize: { xs: 20, sm: 20, md: 25, lg: 25 },
          my: { xs: 1, lg: 2 }
        }}>
          {item.text}
        </Typography>
      </GridItem>
    ))}
  </Grid >
);

export default FeaturesSection;
