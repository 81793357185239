import { AxiosResponse } from "axios";
import { getRefreshToken } from "../../contexts/Auth/authUtils";
import { User } from "../../contexts/UserContext/UserReducer";
import { axiosLogin, axiosApi } from "../axios";
import { routes } from "../routes";

interface Credentials {
  email: string,
  password: string,
  // clientId: string,
  // clientSecret: string, --> to verify if needed
  // grantType: string,
}

export interface LoginResult {
  accessToken: string,
  refreshToken: string,
  expiresAt: string,
}

const login = async (credentials: Credentials) => {
  try {
  const response = await axiosLogin.post<LoginResult>(routes.LOGIN, credentials);
  return response;
  } catch (errorr) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return errorr  as AxiosResponse<LoginResult, any>;
  }
};

const getUser = async () => {
  const response = await axiosApi.get<User>(routes.USER_ACCOUNT);
  return response;
};

const refreshAccessToken = async () => {
  const refreshToken = {refreshToken: getRefreshToken()};
  const response = await axiosLogin.post<LoginResult>(routes.REFRESH_TOKEN, refreshToken);
  return response;
};

export {
  login,
  refreshAccessToken,
  getUser
};