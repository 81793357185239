import { VehicleNotification, VehicleNotificationCreation } from "../../interfaces";
import { axiosApi } from "../axios";
import { routes } from "../routes";

const getVehicleNotifications = async (id: string) => {
  const response = await axiosApi.get<VehicleNotification>(routes.VEHICLE_NOTIFICATION(id));
  return response.data;
};

const addVehicleNotification = async (notification: VehicleNotificationCreation) => {
  const response = await axiosApi.post<VehicleNotificationCreation>(routes.NOTIFICATION, notification);
  return response.data;
};

const updateVehicleNotification = async (id: string, notification: Partial<VehicleNotificationCreation>) => {
  const response = await axiosApi.patch<VehicleNotificationCreation>(routes.VEHICLE_NOTIFICATION(id), notification);
  return response.data;
};

const deleteVehicleNotification = async (id: string) => {
  const response = await axiosApi.delete(routes.VEHICLE_NOTIFICATION(id));
  return response.data;
};

export {
  getVehicleNotifications,
  addVehicleNotification,
  updateVehicleNotification,
  deleteVehicleNotification,
};
