import React, { FC } from 'react';
import { Box, Grid, Typography, styled } from '@mui/material';

import formBackground from '../../../../../assets/images/formBackground.png';
import ContactForms from './ContactForms';
import { colorsTheme } from '../../../../../themes/theme';

export const GridItemBackground = styled(Grid)<{ backgroundimage: string }>(({ backgroundimage }) => ({
  backgroundImage: `url(${backgroundimage})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: 'center center',
  textAlign: 'center',
  color: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '15px',
}));

const ContactSection: FC = () => (
  <Box maxWidth={1400}
    sx={{
      px: { xs: 2.5, sm: 2.5, md: 2.5, lg: 5 },
      m: '0px auto',
      mb: 10,
    }}>
    <Typography
      sx={{
        color: colorsTheme.primary.main,
        fontSize: { xs: 24, sm: 32, md: 45, lg: 60 },
        textAlign: 'center',
        mb: { xs: 2, sm: 2, md: 3, lg: 3 },
      }}>
      Masz pytania? Skontaktuj się z nami!
    </Typography>
    <Grid
      container
      columns={{ xs: 4, sm: 120, md: 120 }}
      justifyContent="center"
      alignItems="center"
    >
      <GridItemBackground
        backgroundimage={formBackground}
        item xs={4} sm={38} md={38}
        sx={{
          height: { xs: 230, sm: 320, md: 355, lg: 380, xl: 380 },
          maxWidth: { lg: 560 },
          mr: { xs: 0, sm: 1, md: 2, lg: 2, xl: 1.5 },
          mb: { xs: 1, sm: 1, md: 1, lg: 2 },
          p: { xs: 2, sm: 1, md: 2, lg: 3, xl: 3 },
        }}
      >
        <Typography sx={{
          fontSize: { xs: 20, sm: 20, md: 28 },
          maxWidth: 300
        }}>
          Wyślij do nas formularz, a my postaramy się
          odpowiedzieć najszybciej, jak to możliwe.
        </Typography>
      </GridItemBackground>
      <Grid item xs={4} sm={79} md={79}>
        <ContactForms />
      </Grid>
    </Grid>
  </Box>

);

export default ContactSection;
