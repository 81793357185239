import React, { FC, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Box, LinearProgress, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import theme from '../../themes/theme';
import { UserContext } from '../../contexts/UserContext/UserContext';
import { VehicleEvent, VehicleEventCreate } from '../../interfaces';
import EditEventForm from './components/EditEventForm';
import { addVehicleEvent, getEvent } from '../../api/services/VehicleEventService';
import { RoutesVars } from '../../const/constRoutes';

const RefreshEventPage: FC = () => {
  const { state: { user } } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { eventId } = useParams();
  const navigate = useNavigate();

  const [eventData, setEventData] = useState<VehicleEvent>();

  const query = useQuery({
    queryKey: ['getEvent', user?.id, eventId],
    queryFn: () => getEvent(eventId as string),
    meta: { errorMessage: 'Błąd podczas ładowania powiadomienia.' },
    retry: false,
  });

  useEffect(() => {
    if (query.data) { setEventData(query.data); }
  }, [query.data]);

  const createMutation = useMutation({
    mutationFn: ({ vehicleId, event }: { vehicleId: string, event: VehicleEventCreate }) => addVehicleEvent(vehicleId, event),
    onSuccess: () => {
      queryClient.invalidateQueries(['getEvents', user?.id]);
      queryClient.invalidateQueries(['getEvent', user?.id, eventId]);
      toast.success('Poprawnie dodano powiadomienie.');
      user && navigate(RoutesVars.COMPANY_EVENTS_LIST(user.companyId));
    },
    onError: () => { toast.error('Błąd podczas dodawania powiadomienia.'); },
    meta: { hideDefaultToast: true }
  });

  const formSubmit = (event: VehicleEventCreate) => {
    console.log(event);
    const vehicleId = eventData?.vehicleId as string;
    createMutation.mutate({ vehicleId, event });
  };

  if (query.isError) return <div>Oppps coś poszło nie tak...</div>;
  if (query.isLoading && !eventData) return <LinearProgress />;

  return (
    <Box sx={{ flexGrow: 1, overflow: 'auto', width: '100%', mt: 1, maxWidth: 1488, m: '0 auto', px: 2, pt: 2 }}>
      <Box sx={{
        border: '1.4px solid gray', width: '80%', maxWidth: 800, m: '0 auto', p: 4, borderRadius: '30px', backgroundColor: theme.palette.grey[100],
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      }}>
        <Typography sx={{ mb: 5, textAlign: 'center', color: 'black', fontSize: 28, fontStyle: 'italic', fontWeight: 'bold' }}>
          Stwórz ponownie powiadomienie
        </Typography>
        {eventData && <EditEventForm {...{ event: eventData, formSubmit: formSubmit, submitText: 'Dodaj' }} />}
      </Box>
    </Box>
  );
};

export default RefreshEventPage;