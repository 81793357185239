export enum VehicleType {
  SOLO = 'Ciężarówka',
  TRUCK_WITH_TRAILER = 'Ciężarówka z przyczepką',
  PASSENGER_BUS = 'BUS',
  INTERNATIONAL_BUS = 'BUS międzynarodowy',
  TRACTOR = 'Ciągnik siodłowy ',
  COACH = 'Autobus',
  TRAILER = 'Naczepa',
}
export const VehicleTypeOptions = [
  {name: VehicleType.SOLO, key: Object.keys(VehicleType)[0]},
  {name: VehicleType.TRUCK_WITH_TRAILER, key: Object.keys(VehicleType)[1]},
  {name: VehicleType.PASSENGER_BUS, key: Object.keys(VehicleType)[2]},
  {name: VehicleType.INTERNATIONAL_BUS, key: Object.keys(VehicleType)[3]},
  {name: VehicleType.TRACTOR, key: Object.keys(VehicleType)[4]},
  {name: VehicleType.COACH, key: Object.keys(VehicleType)[5]},
  {name: VehicleType.TRAILER, key: Object.keys(VehicleType)[6]},
];
export enum VehicleTypeKeys {
  SOLO = 'SOLO',
  TRUCK_WITH_TRAILER = 'TRUCK_WITH_TRAILER',
  PASSENGER_BUS = 'PASSENGER_BUS',
  INTERNATIONAL_BUS = 'INTERNATIONAL_BUS',
  TRACTOR = 'TRACTOR',
  COACH = 'COACH',
  TRAILER = 'TRAILER',
}