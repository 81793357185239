export type routesId = string | number;

export const RoutesVars = {
  HOME: '/',
  LOGIN: '/login',
  CONTACT: '/contact-us',
  COMPANY: (id: routesId): string => `/company/${id}`,
  COMPANY_VEHICLES_LIST: (id: routesId): string => `/company/${id}/vehicles`,
  COMPANY_VEHICLE_DETAILS: (id: routesId, vehicleId: routesId): string => `/company/${id}/vehicle/${vehicleId}/details`,
  COMPANY_VEHICLE_NOTIFICATION: (id: routesId, vehicleId: routesId): string => `/company/${id}/vehicle/${vehicleId}/notification`,
  COMPANY_EVENTS_LIST: (id: routesId): string => `/company/${id}/events`,
  COMPANY_EVENTS_HISTORY_LIST: (id: routesId): string => `/company/${id}/events/history`,
  COMPANY_EVENTS_REFRESH: (id: routesId, eventId: string): string => `/company/${id}/events/${eventId}/refresh`,
  COMPANY_EVENT: (id: routesId): string => `/company/${id}/add-event`,
};
