import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs';
import { Toaster } from 'react-hot-toast';
import PiwikPro from '@piwikpro/react-piwik-pro';

import App from './App';
import AppTheme from './themes/theme';

const containerId = process.env.REACT_APP_ANALYTICS_CONTAINER_ID as string;
const containerUlr = process.env.REACT_APP_ANALYTICS_CONTAINER_URL as string;

if (process.env.NODE_ENV === 'production') {
  PiwikPro.initialize(containerId, containerUlr);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={AppTheme}>
        <CssBaseline enableColorScheme />
        <App />
        <Toaster toastOptions={{ duration: 3000, position: 'bottom-right' }} />
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>
);
