import React, { FC, useContext, useMemo, useState } from 'react';
import { DataGrid, GridToolbar, GridColDef, plPL, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid';
import { Backdrop, Box, Button, Fade, Modal, Tooltip } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import PostAddIcon from '@mui/icons-material/PostAdd';

import { VehicleEvent } from '../../../interfaces';
import { createRowData } from './utils';
import TableEmptyState from '../../../components/TableEmptyState';
import EditEventForm from './EditEventForm';
import { deleteVehicleEvent, updateVehicleEvent } from '../../../api/services/VehicleEventService';
import EventTypeTag from '../../../components/shared/Tags/EventTypeTag';
import EventStatusTag from '../../../components/shared/Tags/EventStatusTag';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import { EventsStatusKeys } from '../../../const/constNotifications';
import { RoutesVars } from '../../../const/constRoutes';
import AlertDialog from '../../VehicleNotificationPage/components/AlertDialog';
import { modalStyle } from '../../VehiclesListPage/VehiclesListPage';
import { getSelectedRowsToExport } from '../../../utils/tables/utils';

type Props = {
  events: VehicleEvent[];
  isLoading: boolean;
};

const AllEventsTable: FC<Props> = ({ events, isLoading }) => {
  const { state: { user } } = useContext(UserContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [eventIdToDelete, setEventIdToDelete] = useState('');
  const [selectedEventVehicleId, setSelectedEventVehicleId] = useState('');
  const [eventToEdit, setEventToEdit] = useState<VehicleEvent>();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEndDialog, setOpenEndDialog] = useState(false);

  const deleteMutation = useMutation({
    mutationFn: ({ vehicleId, eventId }: { vehicleId: string, eventId: string }) => deleteVehicleEvent(vehicleId, eventId),
    onSuccess: () => {
      queryClient.invalidateQueries(['getEvents', user?.id]);
      toast.success('Poprawnie usunięto powiadomienie.');
    },
    onError: () => { toast.error('Błąd podczas usuwania powiadomienia.'); },
    meta: { hideDefaultToast: true }
  });

  const updateMutation = useMutation({
    mutationFn: ({ vehicleId, event }: { vehicleId: string, event: Partial<VehicleEvent> }) => updateVehicleEvent(vehicleId, event),
    onSuccess: () => {
      queryClient.invalidateQueries(['getVehicleNotifications', user?.id, selectedEventVehicleId]);
      queryClient.invalidateQueries(['getEvents', user?.id]);
      toast.success('Poprawnie edytowano powiadomienie.');
    },
    onError: () => { toast.error('Błąd podczas edycji powiadomienia.'); },
    meta: { hideDefaultToast: true }
  });

  const endEventMutation = useMutation({
    mutationFn: ({ vehicleId, event }: { vehicleId: string, event: Partial<VehicleEvent> }) => updateVehicleEvent(vehicleId, event),
    onSuccess: () => {
      queryClient.invalidateQueries(['getVehicleNotifications', user?.id, selectedEventVehicleId]);
      queryClient.invalidateQueries(['getEvents', user?.id]);
      toast.success('Poprawnie zakończono powiadomienie.');
    },
    onError: () => { toast.error('Błąd podczas zakańczania powiadomienia.'); },
    meta: { hideDefaultToast: true }
  });

  const handleDeleteEvent = (vehicleId: string, eventId: string) => {
    deleteMutation.mutate({ vehicleId, eventId });
  };

  const handleOpenEditModal = (event: VehicleEvent) => {
    setOpenEditModal(true);
    setEventToEdit(event);
    setSelectedEventVehicleId(event.vehicleId);
  };

  const formSubmitEditEvent = (event: Partial<VehicleEvent>) => {
    updateMutation.mutate({ vehicleId: selectedEventVehicleId, event });
    setOpenEditModal(false);
  };

  const handleClickOpenDeleteDialog = (event: VehicleEvent) => {
    setEventIdToDelete(event.id);
    setSelectedEventVehicleId(event.vehicleId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    handleDeleteEvent(selectedEventVehicleId, eventIdToDelete);
    setOpenDeleteDialog(false);
  };

  const handleClickOpenEndDialog = (event: VehicleEvent) => {
    setEventToEdit(event);
    setSelectedEventVehicleId(event.vehicleId);
    setOpenEndDialog(true);
  }
    ;
  const handleCloseAndCreateConfirmaton = () => {
    user && navigate(RoutesVars.COMPANY_EVENTS_REFRESH(user.companyId, eventToEdit?.id as string));
    if (eventToEdit) {
      const event = {
        name: eventToEdit.name,
        start: eventToEdit.start,
        finish: eventToEdit.finish,
        status: EventsStatusKeys.FINISHED,
        endMileage: eventToEdit.endMileage,
        id: eventToEdit.id
      };
      endEventMutation.mutate({ vehicleId: selectedEventVehicleId, event });
    }
  };

  const handleCloseEndDialog = () => {
    if (eventToEdit) {
      const event = {
        name: eventToEdit.name,
        start: eventToEdit.start,
        finish: eventToEdit.finish,
        status: EventsStatusKeys.FINISHED,
        endMileage: eventToEdit.endMileage,
        id: eventToEdit.id
      };
      endEventMutation.mutate({ vehicleId: selectedEventVehicleId, event });
    }
    setOpenEndDialog(false);
  };

  const renderDeleteButton = (props: GridRenderCellParams<GridValidRowModel, string>) => {
    return (
      <Tooltip title="Usuń powiadomienie">
        <IconButton color='error' onClick={() => handleClickOpenDeleteDialog(props as unknown as VehicleEvent)}>
          <DeleteIcon cy-testId={`DeleteButton-${(props as unknown as VehicleEvent).name}`} />
        </IconButton>
      </Tooltip>

    );
  };

  const renderEndButton = (props: GridRenderCellParams<GridValidRowModel, string>) => {
    return (
      <Tooltip title="Zakończ powiadomienie">
        <IconButton color='success' onClick={() => handleClickOpenEndDialog(props as unknown as VehicleEvent)}>
          <CheckCircleIcon cy-testId={`EndButton-${(props as unknown as VehicleEvent).name}`} />
        </IconButton>
      </Tooltip>

    );
  };

  const renderEditButton = (props: GridRenderCellParams<GridValidRowModel, VehicleEvent>) => {
    return (
      <Tooltip title="Edytuj powiadomienie">
        <IconButton color='primary' onClick={() => handleOpenEditModal(props as unknown as VehicleEvent)}>
          <EditIcon cy-testId={`EditButton-${(props as unknown as VehicleEvent).name}`} />
        </IconButton>
      </Tooltip>

    );
  };

  const renderDescriptionIcon = (description: string) => {
    return (
      <Tooltip title={description || "Nie dodano opisu."}>
        <IconButton color='warning'>
          <PostAddIcon />
        </IconButton>
      </Tooltip>

    );
  };

  const renderEndMileage = (endMileage: number | null) => {
    const displayEndMilage = (endMileage === 0 || endMileage) ? endMileage : '-';
    return (
      <div>{displayEndMilage}</div>
    );
  };

  const renderFinish = (finish: string | null) => {
    const displayFinish = finish || '-';
    return (
      <div>{displayFinish}</div>
    );
  };

  const renderLicensePlateButton = (props: GridRenderCellParams<GridValidRowModel, string>) => {
    const { vehicleId, licensePlateExtended } = props as unknown as VehicleEvent;
    return (
      <Tooltip title="Przejdź do powiadomień">
        <Button
          variant='text'
          onClick={() => user && navigate(RoutesVars.COMPANY_VEHICLE_NOTIFICATION(user.companyId, vehicleId))}
          sx={{ textDecoration: 'none', color: 'black' }}
        >
          {licensePlateExtended}
        </Button>
      </Tooltip>
    );
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Nr.', flex: 10 },
    { field: 'typeMessage', headerName: 'Typ Powiadomienia', flex: 10 },
    { field: 'name', headerName: 'Powiadomienie', flex: 25 },
    {
      field: 'licensePlateExtended', headerName: 'Pojazd', flex: 14,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, VehicleEvent>) => renderLicensePlateButton(params.row)
    },
    {
      field: 'finish', headerName: 'Termin wykonania', headerAlign: 'center', align: 'center', flex: 20,
      renderCell: (params: GridRenderCellParams<GridValidRowModel, string>) => renderFinish(params.row.finish),
    },
    { field: 'start', headerName: 'Data dodania', headerAlign: 'center', align: 'center', width: 120 },
    {
      field: 'endMileage', headerName: 'Limit (km)', headerAlign: 'center', align: 'center', flex: 14,
      renderCell: (params: GridRenderCellParams<GridValidRowModel, string>) => renderEndMileage(params.row.endMileage),
    },
    {
      field: 'status', headerName: 'Status', headerAlign: 'center', align: 'center', flex: 14,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, VehicleEvent>) => EventStatusTag(params.row)
    },
    {
      field: 'type', headerName: 'Termin', headerAlign: 'center', align: 'center', flex: 14,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, VehicleEvent>) => EventTypeTag(params.row)
    },
    {
      field: 'descriptionIcon', headerName: 'Dod. Informacje', headerAlign: 'center', align: 'center', flex: 3,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, VehicleEvent>) => renderDescriptionIcon(params.row.description),
    },
    {
      field: 'editButton', headerName: 'Edytuj Powidomienie', headerAlign: 'center', align: 'center', flex: 3,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, VehicleEvent>) => renderEditButton(params.row),
    },
    {
      field: 'endButton', headerName: 'Zakończ Powidomienie', headerAlign: 'center', align: 'center', flex: 3,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, string>) => renderEndButton(params.row),
    },
    {
      field: 'deleteButton', headerName: 'Usuń Powidomienie', headerAlign: 'center', align: 'center', flex: 3,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, string>) => renderDeleteButton(params.row),
    },
  ];

  const tableRows = useMemo(() => events.map((event) => {
    const { id, vehicleId, name, licensePlateExtended, start, finish, status, endMileage, type, typeMessage, description } = event;
    return createRowData(id, vehicleId, typeMessage, name, licensePlateExtended, start, finish, status, endMileage, type, description, event, event);
  }), [events]);

  return (
    <div style={{ width: '100%', height: '78vh' }}>
      <DataGrid
        rows={tableRows}
        columns={columns}
        loading={isLoading}
        checkboxSelection
        slotProps={{
          toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
        }}
        initialState={{
          pagination: { paginationModel: { page: 0, pageSize: 30 } },
          columns: {
            columnVisibilityModel: {
              id: false,
              idVehicle: false,
              typeMessage: false,
              start: false,
              descriptionIcon: false,
            },
          },
        }}
        slots={{
          noRowsOverlay: () => TableEmptyState({ text: 'Brak powiadomień do wyświetlenia...', isButtonHidden: true }),
          toolbar: GridToolbar
        }}
        pageSizeOptions={[5, 10, 20, 30, 50, 100]}
        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
      />
      {eventToEdit && openEditModal && (
        <Modal
          aria-labelledby="add-notification-modal"
          open={openEditModal}
          onClose={() => setOpenEditModal(false)} //TODO: handle empty list
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{ backdrop: { timeout: 500 } }}
        >
          <Fade in={openEditModal}>
            <Box sx={modalStyle}>
              <EditEventForm {...{ event: eventToEdit, formSubmit: formSubmitEditEvent, submitText: 'Edytuj' }} />
            </Box>
          </Fade>
        </Modal>
      )
      }
      <AlertDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        handleCloseConfirmaton={handleCloseDeleteDialog}
        title='Usuń powiadomienie'
        content='Jesteś pewny ze chcesz usunąć powiadomienie? Zmiany nie można cofnąć!'
        approvalActionText='Usuń'
        cancelActionText='Odrzuć'
      />
      <AlertDialog
        open={openEndDialog}
        handleClose={() => setOpenEndDialog(false)}
        handleCloseConfirmaton={handleCloseEndDialog}
        title='Zakończ powiadomienie'
        content='Jesteś pewny ze chcesz zakończyć to powiadomienie? Zmiany nie można cofnąć!'
        approvalActionText='Zakończ'
        cancelActionText='Odrzuć'
        handleCloseAndCreateConfirmaton={handleCloseAndCreateConfirmaton}
        eventToEnd={eventToEdit?.id}
      />
    </div >
  );
};

export default AllEventsTable;
