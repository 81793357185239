import { Vehicle } from "../../../interfaces";

export function createRowData(
  id: string,
  licensePlate: string,
  owner: string,
  brand: string,
  model: string,
  type: string,
  mileage: number,
  renderDeleteButton: Vehicle,
): Partial<Vehicle> & {id: string, mileage: number; renderDeleteButton: Vehicle} {
  return {
    id,
    licensePlate,
    owner,
    brand,
    model,
    type,
    mileage,
    renderDeleteButton,
  };
}
