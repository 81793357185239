import React from 'react';
import { Chip, Tooltip } from '@mui/material';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined';
import DirectionsBusOutlinedIcon from '@mui/icons-material/DirectionsBusOutlined';
import AgricultureOutlinedIcon from '@mui/icons-material/AgricultureOutlined';
import RvHookupIcon from '@mui/icons-material/RvHookup';

import { VehicleType, VehicleTypeKeys } from '../../../const/constVehicle';

const VehicleTypeTag = (type: string) => {
  const icon = () => {
    switch (type) {
      case VehicleTypeKeys.SOLO: return <DirectionsCarOutlinedIcon color="inherit" />;
      case VehicleTypeKeys.TRUCK_WITH_TRAILER: return <LocalShippingOutlinedIcon color="inherit" />;
      case VehicleTypeKeys.PASSENGER_BUS: return <AirportShuttleOutlinedIcon color="inherit" />;
      case VehicleTypeKeys.INTERNATIONAL_BUS: return <DirectionsBusOutlinedIcon color="inherit" />;
      case VehicleTypeKeys.TRACTOR: return <AgricultureOutlinedIcon color="inherit" />;
      case VehicleTypeKeys.COACH: return <LocalShippingOutlinedIcon color="inherit" />;
      case VehicleTypeKeys.TRAILER: return <RvHookupIcon color="inherit" />;

      default: return <AutoAwesomeOutlinedIcon color="inherit" />;
    }
  };

  const style = () => {
    const common = { fontWeight: "bold", borderRadius: "3px" };
    // const blue = { ...common, backgroundColor: '#DEEBFF', color: '#172B4D', };
    const gray = { ...common, backgroundColor: '#F4F5F7', color: '#42526E', };

    switch (type) {
      case VehicleTypeKeys.SOLO: return { ...gray };
      case VehicleTypeKeys.TRUCK_WITH_TRAILER: return { ...gray };
      case VehicleTypeKeys.PASSENGER_BUS: return { ...gray };
      case VehicleTypeKeys.INTERNATIONAL_BUS: return { ...gray };
      case VehicleTypeKeys.TRACTOR: return { ...gray };
      case VehicleTypeKeys.COACH: return { ...gray };
      case VehicleTypeKeys.TRAILER: return { ...gray };

      default: return { ...common, backgroundColor: '#F4F5F7', color: '#42526E', };
    }
  };

  return (
    <Tooltip title={VehicleType[type as keyof typeof VehicleType]}>
      <Chip
        label={VehicleType[type as keyof typeof VehicleType]}
        icon={icon()}
        sx={style()}
      />
    </Tooltip>

  );
};

export default VehicleTypeTag;