import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { default as AppBarMUI } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';

import { RoutesVars } from '../../../const/constRoutes';
import { UserContext } from '../../../contexts/UserContext/UserContext';

function AppBar() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { actions: { logoutUser } } = React.useContext(UserContext);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => { setAnchorElNav(event.currentTarget); };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => { setAnchorElUser(event.currentTarget); };
  const handleCloseNavMenu = (route: string) => { setAnchorElNav(null); navigate(route); };
  const handleCloseUserMenu = (route: string) => {
    setAnchorElUser(null);
    route === RoutesVars.LOGIN && logoutUser();
    navigate(route);
  };

  if (!id) return null;

  const pages = [
    { name: 'Powiadomienia', route: RoutesVars.COMPANY_EVENTS_LIST(id) },
    { name: 'Pojazdy', route: RoutesVars.COMPANY_VEHICLES_LIST(id) },
    // { name: 'Dodaj Powiadomienie', route: RoutesVars.COMPANY_EVENT(id) },
    { name: 'Historia', route: RoutesVars.COMPANY_EVENTS_HISTORY_LIST(id) },
  ];
  const settings = [
    // { name: 'Ustawienia', route: RoutesVars.LOGIN },
    // { name: 'Pomoc', route: RoutesVars.LOGIN },
    { name: 'Wyloguj', route: RoutesVars.LOGIN },
  ];

  return (
    <AppBarMUI position="static" >
      <Container maxWidth="xl" >
        <Toolbar disableGutters >
          <ShareLocationIcon
            sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, cursor: 'pointer' }}
            onClick={() => navigate(RoutesVars.COMPANY_EVENTS_LIST(id))}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={() => navigate(RoutesVars.COMPANY_EVENTS_LIST(id))}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.2rem',
              color: 'inherit',
              textDecoration: 'none',
              cursor: 'pointer'
            }}
          >
            Kieruj Flotą |
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={() => handleCloseNavMenu(page.route)}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <ShareLocationIcon
            sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, cursor: 'pointer' }}
            onClick={() => navigate(RoutesVars.COMPANY_EVENTS_LIST(id))}
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            onClick={() => navigate(RoutesVars.COMPANY_EVENTS_LIST(id))}
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Kieruj Flotą
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => handleCloseNavMenu(page.route)}
                sx={{
                  color: 'white', display: 'block',
                  '&:hover': {
                    background: "#1565c0",
                    textDecoration: 'underline',
                  },
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Ustawienia">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.name} sx={{ cursor: 'pointer' }} onClick={() => { handleCloseUserMenu(setting.route); }}>
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBarMUI >
  );
}
export default AppBar;