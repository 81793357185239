import React from "react";
import { Chip, Tooltip } from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AlarmOffOutlinedIcon from '@mui/icons-material/AlarmOffOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';

import { EventsTypeKeys } from "../../../const/constNotifications";
import { VehicleEvent } from "../../../interfaces";

const EventTypeTag = (props: VehicleEvent) => {
  const { type, typeMessage } = props;

  const icon = () => {
    switch (type) {
      case EventsTypeKeys.AFTER_DEAD_LINE_MILEAGE: return <RouteOutlinedIcon color="inherit" />;
      case EventsTypeKeys.SMALL_MILEAGE: return <RouteOutlinedIcon color="inherit" />;
      case EventsTypeKeys.MID_MILEAGE: return <RouteOutlinedIcon color="inherit" />;
      case EventsTypeKeys.LARGE_MILEAGE: return <RouteOutlinedIcon color="inherit" />;

      case EventsTypeKeys.AFTER_DEAD_LINE_DATE: return <CalendarMonthOutlinedIcon color="inherit" />;
      case EventsTypeKeys.SMALL_DATE: return <CalendarMonthOutlinedIcon color="inherit" />;
      case EventsTypeKeys.MID_DATE: return <CalendarMonthOutlinedIcon color="inherit" />;
      case EventsTypeKeys.LARGE_DATE: return <CalendarMonthOutlinedIcon color="inherit" />;

      case EventsTypeKeys.WITHOUT_ALERT: return <AlarmOffOutlinedIcon color="inherit" />;
      case EventsTypeKeys.CURRENT_DATE: return <AlarmOffOutlinedIcon color="inherit" />;

      default: return <AutoAwesomeOutlinedIcon color="inherit" />;
    }
  };

  const style = () => {
    const common = { fontWeight: "bold", borderRadius: "3px" };
    const afterDeadLine = { ...common, backgroundColor: '#BF2600', color: '#FFFFFF', };
    const smallRange = { ...common, backgroundColor: '#FFEBE6', color: '#BF2600', };
    const midRange = { ...common, backgroundColor: '#f4c387', color: '#172B4D', }; // #FF991F
    const largeRange = { ...common, backgroundColor: '#FFF0B3', color: '#172B4D', };
    const outOfRange = { ...common, backgroundColor: '#E3FCEF', color: '#006644', };
    const currentDay = { ...common, backgroundColor: '#FF991F', color: '#172B4D', };

    switch (type) {
      case EventsTypeKeys.AFTER_DEAD_LINE_MILEAGE: return { ...afterDeadLine };
      case EventsTypeKeys.SMALL_MILEAGE: return { ...smallRange };
      case EventsTypeKeys.MID_MILEAGE: return { ...midRange };
      case EventsTypeKeys.LARGE_MILEAGE: return { ...largeRange };

      case EventsTypeKeys.AFTER_DEAD_LINE_DATE: return { ...afterDeadLine };
      case EventsTypeKeys.SMALL_DATE: return { ...smallRange };
      case EventsTypeKeys.MID_DATE: return { ...midRange };
      case EventsTypeKeys.LARGE_DATE: return { ...largeRange };

      case EventsTypeKeys.WITHOUT_ALERT: return { ...outOfRange };
      case EventsTypeKeys.CURRENT_DATE: return { ...currentDay };

      default: return { ...common, backgroundColor: '#F4F5F7', color: '#42526E', };
    }
  };

  return (
    <Tooltip title={typeMessage}>
      <Chip
        label={typeMessage}
        icon={icon()}
        sx={style()}
      />
    </Tooltip>
  );
};

export default EventTypeTag;
