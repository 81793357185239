import React, { FC } from 'react';
import { Box, styled } from '@mui/material';
import { PageViews } from '@piwikpro/react-piwik-pro';

import AppBarGuest from '../../../components/shared/AppBar/AppBarGuest';
import Footer from '../../../components/shared/Footer';
import IntroSection from './Components/IntroSection';
import LaptopSection from './Components/LaptopSection';
import BenefitsSection from './Components/BenefitsSection';
import TabletSection from './Components/TabletSection';
import ContactSection from './Components/ContactSection/ContactSection';
import LogoSection from './Components/LogoSection';
import FeaturesSection from './Components/FeaturesSection';
import ScrollToHashElement from '../../../utils/ScrollToHashElement';

const LayoutContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const GuestMainPage: FC = () => {
  PageViews.trackPageView('View Guest Main Page');

  return (
    <LayoutContainer>
      <AppBarGuest />
      <div>
        <Box sx={{ flexGrow: '1', m: '0px auto' }}>

          <IntroSection />

          <Box id="about" sx={{ height: 100, width: '100%' }}></Box>

          <LaptopSection />

          <BenefitsSection />

          <TabletSection />

          <ContactSection />

          <LogoSection />

          <FeaturesSection />

        </Box>
      </div>
      <ScrollToHashElement />
      <Footer />
    </LayoutContainer >
  );
};

export default GuestMainPage;
