// baseUrl has to have slash at the end
const apiUrl: string = process.env.REACT_APP_AUTH_URL as string;

const routes = {
LOGIN: 'auth/login',
REFRESH_TOKEN: 'auth/refresh-token',
USER_ACCOUNT: 'user/account',
VEHICLE: (vehicleId = ''): string => `vehicle/${vehicleId}`,
VEHICLES: 'vehicle',
NOTIFICATION: 'notification',
VEHICLE_NOTIFICATION: (vehicleId = ''): string => `notification/${vehicleId}`,
GET_VEHICLE_EVENT: (eventId = ''): string => `notification/event/${eventId}`,
VEHICLE_EVENTS: 'notification/events',
VEHICLE_EVENTS_HISTORY: '/notification/events/finished',
ADD_VEHICLE_EVENT: (vehicleId = ''): string => `notification/addEvent/${vehicleId}`,
VEHICLE_EVENT: (vehicleId = '', eventId = ''): string => `notification/${vehicleId}/${eventId}`,
};

export { routes };
export default apiUrl;