import * as React from 'react';
import { Backdrop, Box, Fade, Modal, Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Vehicle, VehicleEventCreate, VehicleNotificationCreation } from '../../../interfaces';
import TableEmptyState from '../../../components/TableEmptyState';
import AddVehicleEventForm from './Forms/AddVehicleEventForm';
import { addVehicleNotification } from '../../../api/services/VehicleNotificationService';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import { toast } from 'react-hot-toast';

export const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  maxWidth: 1000,
  p: 5,
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid #000000ca',
  boxShadow: 24,
  minHeight: 380,
};

type Props = {
  vehicle: Vehicle;
};

const NoNotifications: React.FC<Props> = ({ vehicle }) => {
  const queryClient = useQueryClient();
  const { state: { user } } = React.useContext(UserContext);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addMutation = useMutation({
    mutationFn: addVehicleNotification,
    onSuccess: () => {
      queryClient.invalidateQueries(['getVehicleNotifications', user?.id, vehicle.id]);
      toast.success('Poprawnie utworzono powiadomienie.');
    },
    onError: () => { toast.error('Błąd podczas tworzenia powiadomienia.'); },
    meta: { hideDefaultToast: true }
  });

  const formSubmit = (event: VehicleEventCreate) => {
    const mutationData: VehicleNotificationCreation = {
      channel: "EMAIL",
      frequencyOption: "STANDARD",
      receiversContact: [],
      status: "ON",
      vehicleId: vehicle.id,
      events: [event],
    };

    addMutation.mutate(mutationData);
    handleClose();
  };

  return (
    <Box sx={{ flexGrow: 1, overflow: 'auto', width: '100%', mt: 1 }}>
      <Stack flexDirection="row" justifyContent="center" alignItems="center" sx={{ height: '50vh' }}>
        <TableEmptyState
          text='Brak powiadomień dla wybranego pojazdu'
          buttonText='Utworz powiadomienie'
          handleClick={handleOpen}
        />
      </Stack>

      <Modal
        aria-labelledby="add-notification-modal"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={open}>
          <Box sx={modalStyle}>
            <AddVehicleEventForm {...{ vehicle, formSubmit, submitText: 'Dodaj' }} />
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default NoNotifications;
