import React, { FC } from 'react';
import { Box, styled } from '@mui/material';

import AppBarGuest from '../../../components/shared/AppBar/AppBarGuest';
import Footer from '../../../components/shared/Footer';
import ContactSection from '../GuestMainPage/Components/ContactSection/ContactSection';
import { PageViews } from '@piwikpro/react-piwik-pro';

const LayoutContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const ContactPage: FC = () => {
  PageViews.trackPageView('View Contact Page');

  return (
    <LayoutContainer>
      <AppBarGuest />

      <Box sx={{ flexGrow: '1', m: '0px auto', pt: 15, }}>

        <ContactSection />

      </Box>

      <Footer />
    </LayoutContainer >
  );
};

export default ContactPage;
