import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import { UserContext } from '../../contexts/UserContext/UserContext';
import { Vehicle, VehicleNotification } from '../../interfaces';
import { getVehicleNotifications } from '../../api/services/VehicleNotificationService';
import NoNotifications from './components/NoNotifications';
import MockedChart from './components/Chart/MockedChart';
import NotificationSettings from './components/NotificationSettings/NotificationSettings';
import VehicleEventTable from './components/VehicleEventTable';
import { getVehicle } from '../../api/services/VehicleService';
import theme from '../../themes/theme';

export const PaperStyleProps = {
  p: 2, display: 'flex', flexDirection: 'column', height: 240, backgroundColor: theme.palette.grey[100], border: '1.4px solid gray',
  boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
};

const VehicleNotificationPage: FC = () => {
  const { vehicleId } = useParams();
  const { state: { user } } = useContext(UserContext);
  const [vehicleNotification, setVehicleNotification] = useState<VehicleNotification>();
  const [vehicle, setVehicle] = useState<Vehicle>();

  const { isLoading: isLoadingVehicle, isFetched: isFetchedVehicle, isError: isErrorVehicle, data: vehicleData } = useQuery({
    queryKey: ['getVehicle', user?.id, vehicleId],
    queryFn: () => getVehicle(vehicleId as string),
    onSuccess: (data: Vehicle) => { setVehicle(data); },
    meta: { errorMessage: 'Błąd podczas ładowania pojazdu.' },
    retry: false,
  });

  const {
    isLoading: isLoadingNotifications,
    isFetched: isFetchedNotifications,
    data: notificationData,
    isError: isErrorNotifications,
    error, refetch } = useQuery({
      queryKey: ['getVehicleNotifications', user?.id, vehicleId],
      queryFn: () => getVehicleNotifications(vehicleId as string),
      onSuccess: (data: VehicleNotification) => { setVehicleNotification(data); },
      onError: (error: AxiosError) => { error.response?.status !== 404 && toast.error('Błąd podczas ładowania powiadomień.'); },
      meta: { hideDefaultToast: true },
      retry: false,
    });

  const isLoading = isLoadingVehicle || isLoadingNotifications;
  const isFetched = isFetchedVehicle && isFetchedNotifications;
  const isDataReady = !!vehicle && !!vehicleNotification;

  useEffect(() => {
    isFetched && notificationData && setVehicleNotification(notificationData); //we need to verify it if
    isFetched && vehicleData && setVehicle(vehicleData); //we need to verify it if
  }, [isFetched, notificationData, vehicleData]);

  if (isLoading) return <LinearProgress />;
  if (isErrorVehicle) return <div>Oppps coś poszło nie tak...</div>;
  if (isErrorNotifications && vehicle && (error as AxiosError).response?.status === 404) return <NoNotifications vehicle={vehicle} />;

  return (
    <Box sx={{ flexGrow: 1, overflow: 'auto', width: '100%', mt: 1, maxWidth: 1488, m: '0 auto', px: 2, pt: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7} >
          <Paper sx={{ ...PaperStyleProps }}>
            <MockedChart />
          </Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper sx={{ ...PaperStyleProps }}>
            {isDataReady && <NotificationSettings vehicleNotification={vehicleNotification} vehicle={vehicle} refetch={refetch} />}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ ...PaperStyleProps, height: 350 }}>
            {isDataReady && <VehicleEventTable events={vehicleNotification.events} isLoading={isLoading} vehicle={vehicle} />}
          </Paper>
        </Grid>
      </Grid>
    </Box >
  );
};

export default VehicleNotificationPage;