import React from 'react';
import { Button, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';

import { VehicleCreate } from '../../../interfaces';
import { VehicleTypeOptions } from '../../../const/constVehicle';

interface Props {
  formSubmit: (vehicle: VehicleCreate) => void;
  submitText: string;
  vehicle?: VehicleCreate;
}

const defaultVehicle = {
  licensePlate: '',
  brand: '',
  model: '',
  type: VehicleTypeOptions[0].name,
  vehicleCard: { mileage: 0 },
  vin: '',
  yearOfManufacture: '',
  owner: '',
};

const AddVehicleForm: React.FC<Props> = ({ vehicle = defaultVehicle, formSubmit, submitText }) => {

  const formik = useFormik({
    initialValues: {
      licensePlate: vehicle.licensePlate,
      brand: vehicle.brand,
      model: vehicle.model,
      type: VehicleTypeOptions[0].name,
      mileage: vehicle.vehicleCard.mileage,
      vin: vehicle.vin,
      yearOfManufacture: vehicle.yearOfManufacture,
      owner: vehicle.owner,
    },
    onSubmit: (values) => {
      const vehicle: VehicleCreate = {
        licensePlate: values.licensePlate,
        brand: values.brand,
        model: values.model,
        type: values.type,
        vehicleCard: { mileage: values.mileage },
        vin: values.vin,
        yearOfManufacture: values.yearOfManufacture,
        owner: values.owner,
      };
      if (values.type === VehicleTypeOptions[0].name) return alert('Musisz wybrać rodzaj pojazdu');
      formSubmit(vehicle);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        required
        id="licensePlate"
        name="licensePlate"
        label="Numer rejestracyjny"
        value={formik.values.licensePlate}
        onChange={formik.handleChange}
        error={formik.touched.licensePlate && Boolean(formik.errors.licensePlate)}
        helperText={formik.touched.licensePlate && formik.errors.licensePlate}
        sx={{ marginBottom: '12px' }}
      />
      <TextField
        fullWidth
        required
        id="brand"
        name="brand"
        label="Marka pojazdu"
        value={formik.values.brand}
        onChange={formik.handleChange}
        error={formik.touched.brand && Boolean(formik.errors.brand)}
        helperText={formik.touched.brand && formik.errors.brand}
        sx={{ mb: '12px' }}
      />
      <TextField
        fullWidth
        required
        id="form-model"
        name="model"
        label="Model pojazdu"
        value={formik.values.model}
        onChange={formik.handleChange}
        error={formik.touched.model && Boolean(formik.errors.model)}
        helperText={formik.touched.model && formik.errors.model}
        sx={{ mb: '12px' }}
      />
      <TextField
        fullWidth
        id="yearOfManufacture"
        name="yearOfManufacture"
        label="Rok produkcji"
        value={formik.values.yearOfManufacture}
        onChange={formik.handleChange}
        error={formik.touched.yearOfManufacture && Boolean(formik.errors.yearOfManufacture)}
        helperText={formik.touched.yearOfManufacture && formik.errors.yearOfManufacture}
        sx={{ mb: '12px' }}
      />
      <TextField
        fullWidth
        id="vin"
        name="vin"
        label="VIN"
        value={formik.values.vin}
        onChange={formik.handleChange}
        error={formik.touched.vin && Boolean(formik.errors.vin)}
        helperText={formik.touched.vin && formik.errors.vin}
        sx={{ mb: '12px' }}
      />
      <TextField
        fullWidth
        id="owner"
        name="owner"
        label="Właściciel"
        value={formik.values.owner}
        onChange={formik.handleChange}
        error={formik.touched.owner && Boolean(formik.errors.owner)}
        helperText={formik.touched.owner && formik.errors.owner}
        sx={{ mb: '12px' }}
      />
      <TextField
        fullWidth
        required
        id="mileage"
        name="mileage"
        inputProps={{ min: 0 }}
        type='number'
        label="Przebieg pojazdu"
        value={formik.values.mileage}
        onChange={formik.handleChange}
        error={formik.touched.model && Boolean(formik.errors.model)}
        helperText={formik.touched.model && formik.errors.model}
        sx={{ mb: '12px' }}
      />

      <Typography sx={{ fontSize: 14, fontStyle: 'italic', mt: 1 }}>
        Wybierz rodzaj pojazdu:
      </Typography>
      <Select
        fullWidth
        id="type"
        name="type"
        label="Rodzaj pojazdu"
        value={formik.values.type}
        onChange={formik.handleChange}
        sx={{ mb: '12px' }}
      >
        {VehicleTypeOptions.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            {option.name}
          </MenuItem>
        ))}
      </Select>

      <Button color="primary" variant="contained" type="submit" fullWidth >
        {submitText}
      </Button>
    </form>
  );
};

AddVehicleForm.defaultProps = {
  vehicle: {
    licensePlate: '',
    brand: '',
    model: '',
    type: VehicleTypeOptions[0].name,
    vehicleCard: { mileage: 0 },
    vin: '',
    yearOfManufacture: '',
    owner: '',
  },
};

export default AddVehicleForm;
