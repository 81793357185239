import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

import { RoutesVars } from './const/constRoutes';
import VehiclesPage from './pages/VehiclesListPage/VehiclesListPage';
import EventsPage from './pages/EventsListPage/EventsListPage';
import { UserContextProvider } from './contexts/UserContext/UserContext';
import LoginPage from './pages/Login/LoginPage';
import PrivateRoute from './components/PrivateRoute';
import CompanyLayout from './layouts/CompanyLayout';
import EventsHistoryPage from './pages/EventsHistoryPage/EventsHistoryPage';
import VehicleNotificationPage from './pages/VehicleNotificationPage/VehicleNotificationPage';
import VehicleDetailsPage from './pages/VehicleDetailsPage/VehicleDetailsPage';
import RefreshEventPage from './pages/RefreshEventPage/RefreshEventPage';
import GuestMainPage from './pages/Guest/GuestMainPage/GuestMainPage';
import ContactPage from './pages/Guest/ContactPage/ContactPage';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // refetchOnMount: false, //?
      // refetchOnReconnect: false, //?
      // retry: false, //?
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (error instanceof AxiosError) {
        if (!query.meta?.hideDefaultToast) {
          if (query.meta && query.meta.errorMessage) {
            const message = query.meta.errorMessage as string;
            toast.error(message);
          } else if (query.state.data === undefined) {
            toast.error(`Ooops coś poszło nie tak: ${error.message}`);
          }
        }
      }
    },
    // onSuccess: (data, query) => {
    //   console.log('QueryCache-onSuccess', data, query);
    //   toast.success('Poprawnie wczytano dane.');
    // },
    // onSettled: (data, error) => {
    //   console.log('QueryCache-onSettled', data, error);
    // },
  }),
});

// const query = queryCache.clear(['posts']) --> po dodaniu nowego auta, i dodaniu nowego eventu

const App = () => {
  const MissingRoute = () => <Navigate to={{ pathname: RoutesVars.LOGIN }} replace />;

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <UserContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<GuestMainPage />} />
              <Route path={RoutesVars.LOGIN} element={<LoginPage />} />
              <Route path={RoutesVars.CONTACT} element={<ContactPage />} />

              <Route path="/company/*" element={<PrivateRoute><CompanyLayout /></PrivateRoute>}>
                <Route path=":id/vehicles" element={<VehiclesPage />} />

                <Route path=":id/events" element={<EventsPage />} />
                <Route path=":id/events/history" element={<EventsHistoryPage />} />
                <Route path=":id/events/:eventId/refresh" element={<RefreshEventPage />} />

                <Route path=":id/vehicle/:vehicleId/notification" element={<VehicleNotificationPage />} />
                <Route path=":id/vehicle/:vehicleId/details" element={<VehicleDetailsPage />} />
                <Route path="*" element={<MissingRoute />} />
              </Route>

              <Route path="*" element={<MissingRoute />} />
            </Routes>
          </BrowserRouter>
          <ReactQueryDevtools />
        </UserContextProvider>

      </QueryClientProvider>
    </div>
  );
};

export default App;

// max width 1488 pading 24 l i 24 r
