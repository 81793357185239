
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';

export type User = {
  id: string,
  email: string,
  companyId: string;
  nip: string,
  companyApps: string[],
  companyName: string,
  regon: string,
};

export type State = {
  user: User | null;
};

export type ReducerAction = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

export const userReducer = (state: State, action: ReducerAction): State => {
  switch (action.type) {
    case FETCH_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case LOGOUT_USER: {
      return { ...state, user: null };
    }
    default:
      return state;
  }
};
