import React from 'react';
import { Box, styled } from '@mui/material';
import { Outlet } from 'react-router-dom';

import AppBar from "../components/shared/AppBar/AppBar";

const LayoutContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const CompanyLayout: React.FunctionComponent = () => (
  <LayoutContainer>
    <AppBar />
    <Box sx={{ padding: 0, flexGrow: '1', }}>
      <Outlet />
    </Box>
  </LayoutContainer>
);

export default CompanyLayout;
