import React, { Dispatch, FC, ReactNode, createContext, useReducer } from "react";
import { ReducerAction, State, userReducer } from "./UserReducer";
import actions, { Action } from "./userActions";

interface ProviderProps {
  children: ReactNode;
}

type EnchancedAction = () => Promise<void>;
type Props = {
  state: State;
  actions: {
    fetchUser: EnchancedAction,
    logoutUser: () => void,
  };
};

export const initialState = {
  user: null,
};
export const initialActions = {
  fetchUser: () => Promise.resolve(),
  logoutUser: () => null,
};

export const UserContext = createContext<Props>({ state: initialState, actions: initialActions });
UserContext.displayName = 'UserContext';

export const enchanceAction = (disptach: Dispatch<ReducerAction>, action: Action): EnchancedAction => () => action(disptach);

export const UserContextProvider: FC<ProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  const fetchUser: EnchancedAction = enchanceAction(dispatch, actions.fetchUser);
  const logoutUser = () => actions.logoutUser(dispatch);

  const enchancedActions = {
    fetchUser,
    logoutUser,
  };

  return (
    <UserContext.Provider value={{ state, actions: enchancedActions }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;