import { LoginResult } from "../../api/services/AccountService";
import { ACCESS_TOKEN, EXPIRES_AT, REFRESH_TOKEN } from "../../const/constApi";

// type PostTokenResponse = {
//   accessToken: string;
//   expiresIn: number;
//   refreshToken: string ;
//   // scope: string;
//   // tokenType: 'Bearer';
// };

const getToken = (): string | null => sessionStorage.getItem(ACCESS_TOKEN);
const setToken = (token: string): void => sessionStorage.setItem(ACCESS_TOKEN, token);

const isAuthenticated = (): boolean => !!getToken();

const isTokenExpired = (): boolean => {
  const expiresAt = sessionStorage.getItem(EXPIRES_AT) as unknown as string;

  if (!expiresAt) return true;

  const currentTime = new Date().getTime();
  const isExpired = currentTime > parseInt(expiresAt, 10);

  return isExpired;
};

const getRefreshToken = (): string | null => sessionStorage.getItem(REFRESH_TOKEN);
const setRefreshToken = (token: string): void => {
  sessionStorage.setItem(REFRESH_TOKEN, token);
};

const setTokenExpirationTime = (expiresAt: string):void => {
  const currentTime = new Date().getTime();
  const expirationTime = new Date(currentTime + expiresAt).getTime();

  sessionStorage.setItem(EXPIRES_AT, expirationTime.toString());
};

const refreshToken = (loginResult: LoginResult): void => {
  setToken(loginResult.accessToken);
  setRefreshToken(loginResult.refreshToken);
  setTokenExpirationTime(loginResult.expiresAt);
};

const deleteToken = (): void => {
  sessionStorage.removeItem(REFRESH_TOKEN);
  sessionStorage.removeItem(EXPIRES_AT);
  sessionStorage.removeItem(ACCESS_TOKEN);
};

export {
  isTokenExpired,
  isAuthenticated,
  setTokenExpirationTime,
  setToken,
  setRefreshToken,
  getToken,
  getRefreshToken,
  deleteToken,
  refreshToken,
};
