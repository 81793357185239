import React from 'react';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import AllEventsTable from './components/AllEventsTable';
import { VehicleEvent } from '../../interfaces';
import SearchInput from '../../components/SearchInput';
import { getEvents } from '../../api/services/VehicleEventService';
import { UserContext } from '../../contexts/UserContext/UserContext';

const EventsListPage: React.FunctionComponent = () => {
  const { state: { user } } = React.useContext(UserContext);
  const [eventsList, setEventsList] = React.useState<VehicleEvent[]>([]);
  const [searchResult, setSearchResult] = React.useState<VehicleEvent[]>([]);

  const { isLoading, isFetched, data: eventsData, isRefetching } = useQuery({
    queryKey: ['getEvents', user?.id],
    queryFn: getEvents,
    onSuccess: (data: VehicleEvent[]) => { setEventsList(data); },
    meta: { errorMessage: 'Błąd podczas ładowania powiadomień.' },
    retry: false,
  });

  React.useEffect(() => {
    isFetched && eventsData && setEventsList(eventsData);
  }, [isFetched, eventsData]);

  const searchKeys = React.useMemo(() => ['name', 'status'], []);
  const searchCallback = React.useCallback(
    (results: VehicleEvent[]) => {
      setSearchResult(results);
    }, [],
  );

  return (
    <div>
      <Box sx={{ px: 2, maxWidth: 1488, m: '0 auto' }}>
        <SearchInput
          list={eventsList}
          keys={searchKeys}
          callback={searchCallback}
          label="Wyszukaj w dostępnych powiadomieniach..."
          isDisabled={isRefetching}
        />
        <AllEventsTable events={searchResult} isLoading={isLoading || isRefetching} />
      </Box>
    </div>
  );
};

export default EventsListPage;