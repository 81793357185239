import React, { FC } from 'react';
import { Box, CardMedia, Grid } from '@mui/material';

import laptop from '../../../../assets/images/Laptop.png';

const LaptopSection: FC = () => (
  <Grid
    container
    alignItems="center"
    maxWidth={1600}
    columns={{ xs: 4, sm: 4, md: 12 }}
    sx={{
      px: { xs: 2.5, sm: 2.5, md: 2.5, lg: 5 },
      m: '0px auto',
      mb: 10,
    }}
  >
    <Grid item xs={4} sm={4} md={6}
      sx={{
        pr: { xs: 0, sm: 0, md: 5, lg: 5 },
        pb: { xs: 2.5, sm: 2.5, md: 0, lg: 0 },
      }}>
      <CardMedia
        component="img"
        image={laptop}
        sx={{ height: 'auto', width: '100%', px: 0 }}
      />
    </Grid>
    <Grid item xs={4} sm={4} md={6}>
      <Box
        sx={{
          fontSize: { xs: 20, sm: 23, md: 25, lg: 30 },
          textAlign: { xs: 'center', sm: 'center', md: 'center' }
        }}>
        Aplikacja dla firm transportowych umożliwiająca kompleksowe zarządzanie
        flotą pojazdów poprzez system powiadomień - przyczyniając się do
        zwiększenia efektywności, bezpieczeństwa i kontrolowania kosztów
        utrzymania pojazdów.
      </Box>
    </Grid>
  </Grid>
);

export default LaptopSection;
