import React, { FC, useEffect, useReducer } from 'react';
import { Button, TextField, FormControlLabel, Checkbox, Link, Stack, Box, Typography, Container, Fade, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { useNavigate, useLocation } from 'react-router-dom';

import reducer, { initialStateLogin } from './reducer';
import { login } from '../../api/services/AccountService';
import { UserContext } from '../../contexts/UserContext/UserContext';
import { RoutesVars } from '../../const/constRoutes';
import { setRefreshToken, setToken, setTokenExpirationTime } from '../../contexts/Auth/authUtils';
import AppBarGuest from '../../components/shared/AppBar/AppBarGuest';
import Footer from '../../components/shared/Footer';
import { PageViews } from '@piwikpro/react-piwik-pro';

const LayoutContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const SubmitButton = styled(Button)({ borderRadius: 40, height: 36, });

const LoginPage: FC = () => {
  const [state, dispatch] = useReducer(reducer, initialStateLogin);
  const navigate = useNavigate();
  // that's a trick to make typescript not treat location.state as unknown
  const { state: locationState } = useLocation() as { state: { from: { pathname: string } } };
  const { state: { user }, actions } = React.useContext(UserContext);

  PageViews.trackPageView('View Login Page');

  useEffect(() => {
    const payload = !(state.username.trim() && state.password.trim());
    dispatch({ type: 'setIsButtonDisabled', payload });
  }, [state.username, state.password]);

  useEffect(() => {
    if (user) {
      const path = locationState?.from ? locationState.from.pathname : RoutesVars.COMPANY_EVENTS_LIST(user.companyId); //TODO: add company id
      navigate(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    const result = await login({ email: state.username, password: state.password });

    if (result.status === 200) {
      const token = result.data;

      setToken(token.accessToken);
      setTokenExpirationTime(token.expiresAt);
      setRefreshToken(token.refreshToken);
      actions.fetchUser();

    } else if (result.status !== 200) {
      dispatch({ type: 'setIsError', payload: true });
    }
  };

  const onInputChange = (type: 'setUsername' | 'setPassword'): React.ChangeEventHandler<HTMLInputElement> => (
    (event) => dispatch({ type, payload: event.target.value })
  );

  return (
    <LayoutContainer>
      <AppBarGuest />
      <Container maxWidth="xs" sx={{ padding: 5, flexGrow: 1 }}>
        <Grid columns={1} alignItems="flex-start" marginTop={7}>
          <Typography component="div" variant="h3" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            Zaloguj się
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Fade in={state.isError}>
              <Alert severity="error">Podany email lub hasło są nieprawidłowe</Alert>
            </Fade>
          </Box>
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              color="primary"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mail"
              name="email"
              autoComplete="email"
              autoFocus
              error={state.isError}
              onChange={onInputChange('setUsername')}
              inputProps={{ 'data-testid': 'email-input' }}
            />
            <TextField
              color="primary"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Hasło"
              type="password"
              id="password"
              error={state.isError}
              autoComplete="current-password"
              helperText={state.helperText}
              onChange={onInputChange('setPassword')}
              inputProps={{ 'data-testid': 'password-input' }}
            />
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ display: 'none' }}>
              <FormControlLabel
                control={<Checkbox value="remember" color="secondary" />}
                label="Zapamiętaj mnie"
                disabled
              />
              <Link href={'/login'} variant="body2" color="secondary">
                Nie pamiętasz hasła?
              </Link>
            </Stack>
            <SubmitButton
              type="submit"
              name="submit-button"
              fullWidth
              variant="contained"
              color="primary"
              disabled={state.isButtonDisabled}
              data-testid="submit-input"
              sx={{ mt: 2 }}
            >
              Zaloguj się
            </SubmitButton>
          </form>
        </Grid>
      </Container>
      <Footer />
    </LayoutContainer>
  );
};

export default LoginPage;
